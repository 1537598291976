import React, { useEffect, useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FiSave } from 'react-icons/fi';
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
};
function ModalSuppliers({ data, setdata }) {
    const { getsuplier, supplier } = useStateContext()
    useEffect(() => {
        getsuplier()
        // eslint-disable-next-line
    }, [])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const addRow = (value) => {
        let obj = {
            name: value?.supplier_name,
            supplier_code: data?.item_no,
            barcode: data?.title,
            lead_time: "1",
            purchase_price: "0",
            currency: "GBP",
            min_order_qty: "0",
            pack_size: "0"
        }
        setdata({ ...data, pro_supplier: [...data?.pro_supplier, obj] })

        setOpen(false);
    }
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = data?.extended_properties?.filter((item, i) => {
        return !select.includes(i);
      });
      setdata({...data,extended_properties:array})
    }
    return (
        <div className='list_main'>

            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <div className='row'>
                    <div className='col-6'>
                        <h5 className='p-2' style={{ color: '#2895d8' }} >Channel SKU:</h5>

                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <div>

                            <button className='btn_listAction' onClick={handleOpen}><IoIosAdd /> Add New</button>
                            <button className='btn_listAction mx-2'onClick={deleteFun}><RiDeleteBin5Line /> Delete</button>
                        </div>
                    </div>
                </div>
                <div className='list_table' style={{ padding: ".4rem" }}>
                    <table className="table table-sm">
                        <thead>
                            <tr>

                                <th scope="col" style={{ width: "70px" }}>Default</th>
                                <th scope="col">Name</th>
                                <th style={{whiteSpace:"nowrap"}} scope="col">Supplier Code</th>
                                <th scope="col">Barcode</th>
                                <th scope="col">Lead Time</th>
                                <th style={{whiteSpace:"nowrap"}} scope="col">Purchase Price</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Min Order</th>
                                <th scope="col">Pack Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.pro_supplier?.map((item, idx) => {
                                return (

                                    <tr onClick={(e)=>getselect(idx, e)} style={{ background: `${isRowSelected(idx) ? "#93B1C9" : ""}`,color: `${isRowSelected(idx) ? "white" : ""}`}}>
                                        <td>{item?.name}</td>
                                        <td>{item?.name}</td>
                                        <td>{item?.supplier_code}</td>
                                        <td><input value={item?.barcode} style={{width:"80px",background:"none",border:"none"}} 
                                           
                                            /></td>
                                        <td>
                                            <input value={item?.lead_time} style={{width:"80px",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.pro_supplier];
                                                updatedData[idx].lead_time = e.target.value;
                                                setdata({...data,pro_supplier:updatedData})
                                            }}
                                            />
                                        </td>
                                        <td>
                                        <input value={item?.purchase_price} style={{width:"80px",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.pro_supplier];
                                                updatedData[idx].purchase_price = e.target.value;
                                                setdata({...data,pro_supplier:updatedData})
                                            }}
                                            />
                                        </td>
                                        <td>{item?.currency}</td>
                                        <td>
                                        <input value={item?.min_order_qty} style={{width:"80px",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.pro_supplier];
                                                updatedData[idx].min_order_qty = e.target.value;
                                                setdata({...data,pro_supplier:updatedData})
                                            }}
                                            />
                                        </td>
                                        <td>
                                        <input value={item?.pack_size} style={{width:"80px",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.pro_supplier];
                                                updatedData[idx].pack_size = e.target.value;
                                                setdata({...data,pro_supplier:updatedData})
                                            }}
                                            />
                                        </td>
                                    

                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>


                </div>



            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "space-between", background: "#44688C", color: "white", padding: "1rem" }}>
                        <h5>Item Chooser</h5>
                        <button style={{ color: "white", background: "none", border: "none" }} onClick={handleClose}>X</button>
                    </div>
                    <div style={{ height: "70vh" }} className='model_childBoxMain'>
                        <div className='list_table' style={{ height: "70vh" }} >
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {supplier?.map((item) => {
                                        return (
                                            <tr onClick={() => addRow(item)}>
                                                <td>{item.supplier_name}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "1.5rem 1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

                        <button className='save_btn mx-2' onClick={() => addRow()}><FiSave /> Save</button>
                        <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalSuppliers