import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal'
import { FiEdit } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius:"10px",
    p: 4,
  };    
  
function Categories() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setdata({name:""})
        setValidation(false)
        setOpen(false)
    };
    const [multipulSelct,setmultipulSelct]=useState([])
    const [data,setdata]=useState({
        name:""
    })
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const { addCategory, getCategory, allCetagore, editCategory, deletCategory } = useStateContext()
    useEffect(() => {
      getCategory()
      // eslint-disable-next-line
    }, [])
    const editOpen=(item)=>{
        handleOpen()
        setdata({
            name:item?.name,
            id:item?.id
        })
    }
    const [validation,setValidation]=useState(false)
    return (
        <div className='categories'>
            <div className='button_section'>
                <button onClick={handleOpen} className='btn_all'><IoIosAdd/> Add New</button>
                <button disabled={multipulSelct?false:true} style={{cursor:`${multipulSelct?"pointer":'not-allowed'}`}} onClick={()=>deletCategory(multipulSelct)} className='btn_all'><RiDeleteBin5Line/>Delete</button>
            </div>
            <div className='table_all'>

                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th style={{width:"20px"}} scope="col"></th>
                            <th scope="col">Name</th>
                            <th style={{width:"20px"}} scope="col">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allCetagore?.map((item,i)=>{
                            return(
                                <tr onClick={(e)=>getselect(item.id, e)} style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}></td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>{item?.name}</td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                    <button style={{background:"none",border:"none" ,color:"gray"}} onClick={()=>editOpen(item)}>
                                    <FiEdit />
                                    </button>
                                </td>
                            </tr>
                            )
                        })}
                     
                     

                    </tbody>
                </table>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <h5>Add New Category</h5>
                <button style={{background:"none",border:"none"}} onClick={handleClose}>X</button>
            </div>
            <div style={{height:"30vh"}}>
                <div>
                <label for="inp_category" style={{fontWeight:"bold",marginTop:"10px"}}>Name*</label>
                        {validation&&<p className='text-danger'>Name field must be required</p>}
                </div>
                <input value={data?.name} id='inp_category' onChange={(e)=>setdata({...data,name:e.target.value})} style={{width:"300px",padding:".5rem",border:"1px solid lightgray",borderRadius:"5px",marginTop:"10px"}} type='text' />
            </div>
            <div style={{display:"flex",justifyContent:"flex-end"}}>
                
                <button style={{padding :".3rem 1rem"}} onClick={()=>{
                    if(data?.id){
                        editCategory(data)
                        handleClose()
                    }
                    else{
                        if(data.name){
                            addCategory(data)
                            handleClose()
                        }
                        else{
                            setValidation(true)
                            setTimeout(() => {
                                setValidation(false)
                            }, 3000);
                        }
                    }
                }} className='btn btn-primary mx-2'>Save</button>
                <button style={{background:"none",border:"none",fontWeight:"bold"}} onClick={handleClose}>Cancel</button>
            </div>
        </Box>
      </Modal>
        </div>
    )
}

export default Categories