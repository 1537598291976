import React, { useEffect, useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Add } from '@mui/icons-material';
import { useStateContext } from '../../Context/ContextProvider';
import ListingRule from './ListingRule';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-30%, -10%)',
  width: 500,
  bgcolor: '#F9F9F9',
  // border: '2px solid #000',
  boxShadow: 24,

};
function ListingDiscription({ data, setdata }) {
    const { getsource, getsubsource, subsource, source } = useStateContext()
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  useEffect(() => {
    getsource()
    getsubsource()
    // eslint-disable-next-line
  }, [])
  const [row, setrow] = useState({
    source_id: "",
    subsource_id: "",
    price: "",
    tags: "",
    rules: [],
  })

  const addrow = () => {
    setdata({ ...data, pricing_descriptions: [...data?.pricing_descriptions, row] })
    handleClose()
    if (row.source_id && row.subsource_id) {
    }
  }
  const [row1, setrow1] = useState({
    source_id: "",
    subsource_id: "",
    title: "",
  })

  const addrow1 = () => {
    setdata({ ...data, title_descriptions: [...data?.title_descriptions, row1] })
    handleClose()
    if (row.source_id && row.subsource_id) {
    }
  }
  const [row2, setrow2] = useState({
    source_id: "",
    subsource_id: "",
    description: "",
  })

  const addrow2 = () => {
    setdata({ ...data, product_descriptions: [...data?.product_descriptions, row2] })
    handleClose()
    if (row.source_id && row.subsource_id) {
    }
  }
  const [multipulSelct,setmultipulSelct]=useState([])
  const [select,setselect]=useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  const deleteFun = () => {
    var array = data?.pricing_descriptions?.filter((item, i) => {
      return !select.includes(i);
    });
    setdata({...data,pricing_descriptions:array})
  };
  const [multipulSelct1,setmultipulSelct1]=useState([])
  const [select1,setselect1]=useState([])
  const getselect1 = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect1([...select1, id]);
      setmultipulSelct1([...multipulSelct1, id])
    } else {
      setselect1([id]);
      setmultipulSelct1([id])
    }
  }
  const isRowSelected1 = (id) => select1.includes(id);
  const deleteFun1 = () => {
    var array = data?.title_descriptions?.filter((item, i) => {
      return !select1.includes(i);
    });
    setdata({...data,title_descriptions:array})
  };
  const [multipulSelct2,setmultipulSelct2]=useState([])
  const [select2,setselect2]=useState([])
  const getselect2 = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect2([...select2, id]);
      setmultipulSelct2([...multipulSelct2, id])
    } else {
      setselect2([id]);
      setmultipulSelct2([id])
    }
  }
  const isRowSelected2 = (id) => select2.includes(id);
  const deleteFun2 = () => {
    var array = data?.product_descriptions?.filter((item, i) => {
      return !select2.includes(i);
    });
    setdata({...data,product_descriptions:array})
  };
  return (
    <div className='list_main'>

      <div style={{ height: "max-content" }} className='inv_tabInp_main'>
        <div className='row'>
          <div className='col-6'>
            <h5 className='p-2' style={{ color: '#2895d8' }} >Product pricing by channel:</h5>

          </div>
          <div className='col-6 d-flex justify-content-end'>
            <div>

              <button className='btn_listAction' onClick={handleOpen}><IoIosAdd /> Add New</button>
              <button className='btn_listAction mx-2' onClick={deleteFun}><RiDeleteBin5Line /> Delete</button>
            </div>
          </div>
        </div>
        <div className='list_table' style={{ padding: ".4rem" }}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Source</th>
                <th scope="col">SubSource</th>
                <th scope="col">Price</th>
                <th scope="col">Tag</th>
                <th scope="col">Rule</th>
              </tr>
            </thead>
            <tbody>
              {data?.pricing_descriptions?.map((item,idx) => {
                return (
                  <tr onClick={(e)=>getselect(idx, e)} style={{ background: `${isRowSelected(idx) ? "#93B1C9" : ""}`,color: `${isRowSelected(idx) ? "white" : ""}`}}>

                    <td></td>
                    <td>{
                      item.source_id === "Default" ? <p style={{ color: "red" }}>Default</p> : <select disabled={true} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                        <option hidden></option>

                        {source?.map((item) => {
                          return (

                            <option value={item.id}>{item.name}</option>
                          )
                        })}

                      </select>}
                    </td>
                    <td>{
                      item.source_id === "Default" ? "" : <select disabled={true} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                        <option hidden></option>

                        {subsource?.map((item) => {
                          return (

                            <option value={item.id}>{item.name}</option>
                          )
                        })}

                      </select>
                    }


                    </td>
                    <td><input style={{border:"none",background:"none",outline:"none",width:"40px"}} onChange={(e) => {
                        const updateData=[...data?.pricing_descriptions]
                        updateData[idx].price=e.target.value
                        setdata({...data,pricing_descriptions:updateData})
                    }} value={item.price} /></td>
                    <td>{item.tags}</td>
                    <td> <ListingRule value={data} price={item.price} rule={item.tags?false:true} setValue={setdata} idx={idx}/></td>
                  </tr>
                )
              })}


            </tbody>
          </table>


        </div>



      </div>
      <div style={{ height: "max-content" }} className='inv_tabInp_main'>
        <div className='row'>
          <div className='col-6'>
            <h5 className='p-2' style={{ color: '#2895d8' }} >Product titles by channel:</h5>

          </div>
          <div className='col-6 d-flex justify-content-end'>
            <div>

              <button className='btn_listAction' onClick={handleOpen1}><IoIosAdd /> Add New</button>
              <button className='btn_listAction mx-2'onClick={deleteFun1}><RiDeleteBin5Line /> Delete</button>
            </div>
          </div>
        </div>
        <div className='list_table' style={{ padding: ".4rem" }}>
          <table className="table table-sm">
            <thead>
              <tr>

                <th scope="col">Source</th>
                <th scope="col">SubSource</th>
                <th scope="col">Title</th>
              </tr>
            </thead>
            <tbody>
                {data?.title_descriptions?.map((item,i)=>{
                    return(

              <tr onClick={(e)=>getselect1(i, e)} style={{ background: `${isRowSelected1(i) ? "#93B1C9" : ""}`,color: `${isRowSelected1(i) ? "white" : ""}`}}>
             <td>{
                      item.source_id === "Default" ? <p style={{ color: "red" }}>Default</p> : <select disabled={true} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                        <option hidden></option>

                        {source?.map((item) => {
                          return (

                            <option value={item.id}>{item.name}</option>
                          )
                        })}

                      </select>}
                    </td>
                    <td>{
                      item.source_id === "Default" ? "" : <select disabled={true} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                        <option hidden></option>

                        {subsource?.map((item) => {
                          return (

                            <option value={item.id}>{item.name}</option>
                          )
                        })}

                      </select>
                    }


                    </td>
                    <td><input style={{border:"none",background:"none",outline:"none",width:"40px"}} onChange={(e) => {
                        const updateData=[...data?.pricing_descriptions]
                        updateData[i].title=e.target.value
                        setdata({...data,pricing_descriptions:updateData})
                    }} value={item.title} /></td>

              </tr>
                    )
                })}

            </tbody>
          </table>


        </div>



      </div>
      <div style={{ height: "max-content" }} className='inv_tabInp_main'>
        <div className='row'>
          <div className='col-6'>
            <h5 className='p-2' style={{ color: '#2895d8' }} >Product descriptions by channel:</h5>

          </div>
          <div className='col-6 d-flex justify-content-end'>
            <div>

              <button className='btn_listAction' onClick={handleOpen2}><IoIosAdd /> Add New</button>
              <button className='btn_listAction mx-2' onClick={deleteFun2}><RiDeleteBin5Line /> Delete</button>
            </div>
          </div>
        </div>
        <div className='list_table' style={{ padding: ".4rem" }}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Source</th>
                <th scope="col">SubSource</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
            {data?.product_descriptions?.map((item,i)=>{
                    return(

              <tr onClick={(e)=>getselect2(i, e)} style={{ background: `${isRowSelected2(i) ? "#93B1C9" : ""}`,color: `${isRowSelected2(i) ? "white" : ""}`}}>
             <td>{
                      item.source_id === "Default" ? <p style={{ color: "red" }}>Default</p> : <select disabled={true} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                        <option hidden></option>

                        {source?.map((item) => {
                          return (

                            <option value={item.id}>{item.name}</option>
                          )
                        })}

                      </select>}
                    </td>
                    <td>{
                      item.source_id === "Default" ? "" : <select disabled={true} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                        <option hidden></option>

                        {subsource?.map((item) => {
                          return (

                            <option value={item.id}>{item.name}</option>
                          )
                        })}

                      </select>
                    }


                    </td>
                    <td><input style={{border:"none",background:"none",outline:"none",width:"40px"}} onChange={(e) => {
                        const updateData=[...data?.product_descriptions]
                        updateData[i].description=e.target.value
                        setdata({...data,product_descriptions:updateData})
                    }} value={item.description} /></td>

              </tr>
                    )
                })}

            </tbody>
          </table>


        </div>



      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ background: "#F9F9F9" }}>

            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Default:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <input type='checkbox' />
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Source:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <select onChange={(e) => setrow({ ...row, source_id: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                  <option hidden></option>
                  {source?.map((item) => {
                    return (

                      <option value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>SubSource:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <select onChange={(e) => setrow({ ...row, subsource_id: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                  <option hidden></option>
                  {subsource?.map((item) => {
                    return (

                      <option value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row my-3'>
                          <div className='col-3' style={{ position: "relative" }}>
                            <label style={{ position: "absolute", right: "0%" }}>Tag:</label>
                          </div>
                          <div className='col-9'>
                            <select onChange={(e) => setrow({ ...row, tags: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                              <option hidden></option>
                              <option value={"BUSINESS"} >BUSINESS</option>
                              <option value={"FBA"} >FBA</option>
                            </select>
                          </div>
                        </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Price:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <input onChange={(e) => setrow({ ...row, price: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }} type='number' />
              </div>
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

            <button
              onClick={() => addrow()}
              className='save_btn mx-2' ><Add /> Add</button>
            <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ background: "#F9F9F9" }}>

            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Default:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <input type='checkbox' />
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Source:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <select onChange={(e) => setrow1({ ...row1, source_id: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                  <option hidden></option>
                  {source?.map((item) => {
                    return (

                      <option value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>SubSource:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <select onChange={(e) => setrow1({ ...row1, subsource_id: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                  <option hidden></option>
                  {subsource?.map((item) => {
                    return (

                      <option value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Title:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <input onChange={(e) => setrow1({ ...row1, title: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }} type='text' />
              </div>
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

            <button
              onClick={() => addrow1()}
              className='save_btn mx-2' ><Add /> Add</button>
            <button className='cancel_btn mx-2' onClick={handleClose1}>Cancel</button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ background: "#F9F9F9" }}>

            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Default:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <input type='checkbox' />
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Source:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <select onChange={(e) => setrow2({ ...row2, source_id: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                  <option hidden></option>
                  {source?.map((item) => {
                    return (

                      <option value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>SubSource:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <select onChange={(e) => setrow2({ ...row2, subsource_id: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                  <option hidden></option>
                  {subsource?.map((item) => {
                    return (

                      <option value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-3 d-flex justify-content-end align-items-center'>
                <label>Description:</label>
              </div>
              <div className='col-9 d-flex justify-content-start align-items-center'>
                <input onChange={(e) => setrow2({ ...row2, description: e.target.value })} style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }} type='text' />
              </div>
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

            <button
              onClick={() => addrow2()}
              className='save_btn mx-2' ><Add /> Add</button>
            <button className='cancel_btn mx-2' onClick={handleClose2}>Cancel</button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default ListingDiscription