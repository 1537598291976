import React, { useState } from 'react'
import { Search } from '@mui/icons-material'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useStateContext } from '../../Context/ContextProvider'
import { BiSave } from 'react-icons/bi'
import { MdOutlineAddBox } from 'react-icons/md'
import ItemStats from '../Inventory/Itemstats'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#F9F9F9',
    // border: '2px solid #000',
    boxShadow: 24,
    // pt: 2,
    // px: 4,
    // pb: 3,
  };
function AddScrab({setdata,data,azab}) {
    const [open, setOpen] =useState(false);
    const {search,searchValue}=useStateContext()

    // console.log(searchValue);
    const [value,setvalue]=useState("")
    const handleOpen = () => {
      setOpen(true);
      setdata()
    };
    const handleClose = () => {
      setOpen(false);
    };
    const [showvariation,setshowVariation]=useState(false)
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
  
        setselect([id]);
        setmultipulSelct([id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const [index,setIndex]=useState()
    const show=(idx)=>{
      setIndex(idx)
      setshowVariation(!showvariation)
    }
    const send=()=>{
      handleClose()
      setdata(multipulSelct[0])
    }
  return (
    <> <button onClick={handleOpen} ><Search/> Manual Search</button>
                    <Modal
        open={open}
        style={{overflowY:"scroll"}}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 700,height:"75vh" }}>

            <div className='generl_settingvariation2'>
              
             <input style={{width:"94%",border:"1px solid lightgray",padding:".3rem"}} onChange={(e)=>setvalue(e.target.value)} placeholder='Search'/>
             <button style={{border:"1px solid lightgray",padding:".2rem .5rem",height:"33px"}} onClick={()=>search(value)} ><Search/></button>
            </div>
            <div className='table_box'>
            <div style={{margin:"1rem",height:"50vh",border:"1px solid lightgray",overflow:"scroll"}} className='table-responsive'>

            <table class="table table-sm">
  <thead style={{background:"#F6F6F6"}}>
    <tr>
      <th style={{width:"40px"}} scope="col">
        <span></span>
      </th>
      <th scope="col">
       <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>SKU</div>
       </div>
        
      </th>
      <th >
      <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>Title</div>

       </div>
      </th>
      <th scope="col">
       <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>Level</div>
    
       </div>
        
      </th>
      <th scope="col">
      <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>Availble</div>

       </div>
      </th>
      <th scope="col">
       <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>In open</div>
    
       </div>
        
      </th>
      <th scope="col">
       <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>Due</div>
    
       </div>
        
      </th>
      <th scope="col">
       <div className='row'>
        <div style={{whiteSpace:"nowrap"}} className='col-11'>Retail Price</div>
    
       </div>
        
      </th>

     
    
    </tr>
  </thead>
  <tbody>
    {searchValue?.map((item,i)=>{
       const finalValue = Array.isArray(item?.products) && item.products.length > 0
       ? (() => {
           var resultArray = item.products.map((product) => {
             if (product?.pro_inventory?.length > 0) {
               var value = 0;
               product.pro_inventory.forEach((itemss) => {
                 value += itemss?.level || 0;
               });
               return value;
             } else {
               return 0; // If pro_inventory is empty, return 0
             }
           });
           return resultArray.reduce((acc, currentValue) => acc + currentValue, 0); // Return the sum of all values
         })()
       : 0; // Set a default value if there are no products or pro_inventory
      return(
        <>
        <tr onClick={(e) => getselect(item, e)}  style={{ background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}` }} >
        <td></td>
        <td style={{color: `${isRowSelected(item) ? "white" : ""}`}}><MdOutlineAddBox onClick={()=>show(i)}/>  {item?.item_no}</td>
        <td style={{color: `${isRowSelected(item) ? "white" : ""}`}}><div style={{width:"100px"}}>{item?.title}</div></td>
        <td style={{color: `${isRowSelected(item) ? "white" : ""}`,textAlign:"center"}}>{finalValue?finalValue:"0"}</td>
        <td style={{color: `${isRowSelected(item) ? "white" : ""}`,background:`${item?.pro_inventory?.level>0?"#AAC99E":"#FF7782"}`,textAlign:"center"}}>{item?.pro_inventory?.level?item?.pro_inventory?.level:"0"}</td>
      
        <td style={{textAlign:"center"}}>0</td>
        <td style={{textAlign:"center"}}>0</td>
        <td>{item.retail_price}</td>
   
      </tr>
    {showvariation && index===i ? <tr >
        <td colspan="8" style={{width:"100%"}}>
          <div className='variation_innerState'> 
        <ItemStats data={item}/>

          </div>
        </td>
       
      </tr>:""}
      </>
      )
    })}

  </tbody>
</table>
  </div>

        </div>
        <div className='d-flex justify-content-end'>
          <button style={{padding:".3rem",border:"1px solid lightgray"}} onClick={send} className='mx-3'><BiSave/> Save</button>
        </div>
        </Box>
      </Modal>
    </>
  )
}

export default AddScrab