import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal'
import { FiEdit, FiFilter, FiSave } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // borderRadius:"10px",
    // p: 4,
  };    
  
function Location() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setdata({ name: "",
        address_1: "",
        address_2: "",
        city: "",
        region: "",
        postcode: "",
        country: ""})
        setValidation(false)
        setOpen(false)
    };
    const [multipulSelct,setmultipulSelct]=useState([])
    const [data,setdata]=useState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        region: "",
        postcode: "",
        country: ""
    })
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const { createWareHouse, WareHouse, wareHouse, editWareHouse, deletWarehouse } = useStateContext()
    useEffect(() => {
      WareHouse()
      // eslint-disable-next-line
    }, [])
    const editOpen=(item)=>{
        handleOpen()
        setdata(item)
    }
    const [validation,setValidation]=useState(false)
    return (
        <div className='categories'>
            <div className='button_section'>
                <button onClick={handleOpen} className='btn_all'><IoIosAdd/> Add New</button>
                <button disabled={multipulSelct?false:true} style={{cursor:`${multipulSelct?"pointer":'not-allowed'}`}} onClick={()=>deletWarehouse(multipulSelct)} className='btn_all'><RiDeleteBin5Line/>Delete</button>
            </div>
            <div className='table_all'>

                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th style={{width:"20px"}} scope="col"></th>
                            <th scope="col">Name</th>
                            <th style={{width:"20px"}} scope="col">FC</th>
                            <th style={{width:"70px"}} scope="col"></th>
                            <th style={{width:"20px"}} scope="col"><FiFilter/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {wareHouse?.map((item,i)=>{
                            return(
                                <tr onClick={(e)=>getselect(item.id, e)} style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}></td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>{item?.name}</td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                    <input type='checkbox'/>
                                </td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`,display:"flex",justifyContent:"center",alignItems:"center"}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                <button style={{width:"100px",margin:".5rem",border:"1px solid lightgray",color:"gray"}} onClick={handleOpen} >Automation</button>

                                </td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                    <button style={{background:"none",border:"none" ,color:"gray"}} onClick={()=>editOpen(item)}>
                                    <FiEdit />
                                    </button>
                                </td>
                            </tr>
                            )
                        })}
                     
                     

                    </tbody>
                </table>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div style={{display:"flex",justifyContent:"space-between",background:"#44688C",color:"white",padding:"1rem"}}>
                <h5>Add New Category</h5>
                <button style={{color:"white",background:"none",border:"none"}} onClick={handleClose}>X</button>
            </div>
            <div style={{background:"#F9F9F9",padding:"1rem"}} className='location'>
            <div>
  {/* Tabs navs */}
  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
    <li className="nav-item" role="presentation">
      <a data-mdb-tab-init className="nav-link active" id="ex1-tab-1" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Tab 1</a>
    </li>
    <li className="nav-item" role="presentation">
      <a data-mdb-tab-init className="nav-link" id="ex1-tab-2" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Tab 2</a>
    </li>
  </ul>
  {/* Tabs navs */}
  {/* Tabs content */}
  <div className="tab-content" id="ex1-content">
    <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
    <div className='row'>
                        {validation&&<p className='text-danger'>Name field must be required</p>}
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Name :</label>
                    </div>
                    <div className='col-9'>

                <input value={data?.name} id='inp_category' onChange={(e)=>setdata({...data,name:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Address 1:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.address_1} id='inp_category' onChange={(e)=>setdata({...data,address_1:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Address 2:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.address_2} id='inp_category' onChange={(e)=>setdata({...data,address_2:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>City/Town :</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.city} id='inp_category' onChange={(e)=>setdata({...data,city:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Region :</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.region} id='inp_category' onChange={(e)=>setdata({...data,region:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Post code :</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.postcode} id='inp_category' onChange={(e)=>setdata({...data,postcode:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Country :</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.country} id='inp_category' onChange={(e)=>setdata({...data,country:e.target.value})} style={{width:"250px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                 

                </div>
    </div>
    <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
      Tab 2 content
    </div>
 
  </div>
  {/* Tabs content */}
</div>

              
            </div>
            <div style={{display:"flex",justifyContent:"flex-end",padding:"1.5rem 1rem",border:"1px solid lightgray",marginTop:"1rem",background:"#F0F0F0"}}>
                
                <button  onClick={()=>{
                    if(data?.id){
                        editWareHouse(data)
                        handleClose()
                    }
                    else{
                        if(data.name){
                            createWareHouse(data)
                            handleClose()
                        }
                        else{
                            setValidation(true)
                            setTimeout(() => {
                                setValidation(false)
                            }, 3000);
                        }
                    }
                }} className='save_btn mx-2'><FiSave /> Save</button>
                <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
            </div>
        </Box>
      </Modal>
        </div>
    )
}

export default Location