import React, { useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import {AiOutlineMinusSquare} from 'react-icons/ai'
import { MdOutlineAddBox } from 'react-icons/md'
import { useStateContext } from '../../Context/ContextProvider';
import Tracked from './Tracked';
function TableSection({ productDetail,setProductDetail,editModal,editModal1,selectedIds,setselectedIds}) {
  const {editPro,multipulSelct,setmultipulSelct,updateVariation}=useStateContext()
  // const headers1 = ["", 'SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Stock Level', 'In Open Orders', 'Availble', 'Minimum Level', 'Tracked'];
  const headers = ["", 'SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Stock Level', 'In Open Orders', 'Availble', 'Minimum Level', 'Tracked'];
  const [columnWidths, setColumnWidths] = useState(() => {
    // Initialize column widths with equal values
    return headers.map(() => 150); // You can adjust the initial width as needed
  });
  const [select,setselect]=useState([])

  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {

      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
      setselectedIds([...selectedIds,id?.id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
      setselectedIds([id?.id])
    }
  }
  const handleColumnResize = (index, width) => {
    const newWidths = [...columnWidths];
    newWidths[index] = width;
    setColumnWidths(newWidths);
  };
  const [showVariation, setShowVariation] = useState(Array(productDetail?.variation?.length).fill(false));
  const isRowSelected = (id) => select.includes(id);
  const toggleVariation = (index) => {
    const newShowVariation = [...showVariation];
    newShowVariation[index] = !newShowVariation[index];
    setShowVariation(newShowVariation);
  };
  return (

    <div className='table_section my-2'>
      <div className='table-responsive'>

        <table style={{ minHeight: "13vh" }} className="table table-sm">
          <thead>
            <tr>
              {productDetail?.coloum?.map((item, index) => {
                // <th scope="col" style={{width:"30px"}}></th>
                return (
                  <th scope="col"
                    style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray", position: "relative" }}
                  >
                    <div style={{ width: `${index === 0 ? "10px" : ""}` }} className="row">
                      <div className="col-8" style={{ overflow: "hidden", fontSize: "13px", whiteSpace: "nowrap" }}>
                        {index === 0 ? "" : item}
                      </div>

                    </div>
                    <div
                      className="resizable-handle"
                      onMouseDown={(e) => {
                        e.preventDefault();
                        const initialX = e.clientX;
                        const column = e.target.parentElement;
                        const columnIndex = Array.from(
                          column.parentElement.children
                        ).indexOf(column);

                        const handleMouseMove = (e) => {
                          const widthDiff = e.clientX - initialX;
                          const newWidth = Math.max(
                            40, // Minimum column width
                            columnWidths[columnIndex] + widthDiff
                          );
                          handleColumnResize(columnIndex, newWidth);
                        };

                        const handleMouseUp = () => {
                          window.removeEventListener('mousemove', handleMouseMove);
                          window.removeEventListener('mouseup', handleMouseUp);
                        };

                        window.addEventListener('mousemove', handleMouseMove);
                        window.addEventListener('mouseup', handleMouseUp);
                      }}
                    />
                  </th>
                )
              })}

            </tr>
          </thead>

          <tbody>
            {productDetail?.variation?.map((item, idx) => {
              const finalValue = Array.isArray(item?.products) && item.products.length > 0
              ? (() => {
                  var resultArray = item.products.map((product) => {
                    if (product?.pro_inventory?.length > 0) {
                      var value = 0;
                      product.pro_inventory.forEach((itemss) => {
                        value += itemss?.level || 0;
                      });
                      return value;
                    } else {
                      return 0; // If pro_inventory is empty, return 0
                    }
                  });
                  return resultArray.reduce((acc, currentValue) => acc + currentValue, 0); // Return the sum of all values
                })()
              : 0; // Set a default value if there are no products or pro_inventory
              return (
                <>
                  <tr>
                    <td></td>
                    <td onClick={()=>editModal1(item)} className='sku'>
                    {showVariation[idx]? <AiOutlineMinusSquare style={{color:"black"}} onClick={() => toggleVariation(idx)} /> :<MdOutlineAddBox style={{color:"black"}} onClick={() => toggleVariation(idx)}  /> } {item?.sku}</td>
                    <td>
                      <div className='table_inp'>
                        <input
                         onBlur={(e)=>{
                          let updatedData=[...productDetail?.variation]
                          updatedData[idx].name = e.target.value;
                          updateVariation(updatedData[idx])
                        }} 
                        onChange={(e) => {
                          let updatedData = [...productDetail?.variation]; // Assuming productDetail and variation are not null or undefined
                          updatedData[idx].name = e.target.value;
                          setProductDetail({...productDetail, variation: updatedData});
                        }}
                        value={item?.name} />
                        <FiEdit />
                      </div>

                    </td>
                    <td> <div className='table_inp'>
                      <input value={item?.barcode}
                       onBlur={(e)=>{
                        let updatedData=[...productDetail?.variation]
                        updatedData[idx].barcode = e.target.value;
                        updateVariation(updatedData[idx])
                      }} 
                      onChange={(e) => {
                        let updatedData = [...productDetail?.variation]; // Assuming productDetail and variation are not null or undefined
                        updatedData[idx].barcode = e.target.value;
                        setProductDetail({...productDetail, variation: updatedData});
                      }}
                      />
                      <FiEdit />
                    </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td><div className='table_inp'>
                     
                      {finalValue}
                    </div></td>
                    <td className='text-center'>0</td>
                    <td className='text-center' style={{ background: `${finalValue > 0 ? "#AAC99E" : "#FF7782"}`, color: "white" }}>
                    {finalValue}
                    </td>
                    <td>0</td>
                    <td>
                      {/* <Tracked/> */}
                    </td>
                  </tr>
                  {showVariation[idx] && item?.products?.map((items, i) => {
                    const finalValue = items?.pro_inventory?.length
                    ? (() => {
                        var value = 0;
                        var resultArray = items.pro_inventory.map((itemss) => {
                          value += itemss?.level || 0;
                          return value;
                        });
                        return resultArray[resultArray.length - 1]; // Return the final accumulated value
                      })()
                    : 0; // Set a default value if pro_inventory is empty
                    return (
                      <tr onClick={(e) => getselect(items, e)} style={{display:`${items?.archive===1?"none":""}`,  background: `${isRowSelected(items) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}></td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}} className='ps-4 sku' onClick={()=>editModal(items)}>{items?.item_no}</td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}>
                          <div className='table_inp'>
                            <input
                             onBlur={(e)=>{
                              let updatedData=[...item?.products]
                              updatedData[i].title = e.target.value;
                              editPro(updatedData[i])
                            }} 
                            onChange={(e) => {
                              let updatedData = [...productDetail?.variation[idx]?.products]; // Assuming productDetail and variation are not null or undefined
                              updatedData[i].title = e.target.value;
                              let updatedVariation = [...productDetail?.variation];
                              updatedVariation[idx].products = updatedData;
                              setProductDetail({...productDetail, variation: updatedVariation});
                            }}
                            value={items?.title} />
                            <FiEdit />
                          </div>

                        </td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}> <div className='table_inp'>
                          <input
                             onBlur={(e)=>{
                              let updatedData=[...item?.products]
                              updatedData[i].barcode = e.target.value;
                              editPro(updatedData[i])
                            }} 
                            onChange={(e) => {
                              let updatedData = [...productDetail?.variation[idx]?.products]; // Assuming productDetail and variation are not null or undefined
                              updatedData[i].barcode = e.target.value;
                              let updatedVariation = [...productDetail?.variation];
                              updatedVariation[idx].products = updatedData;
                              setProductDetail({...productDetail, variation: updatedVariation});
                            }}
                          value={items?.barcode} />
                          <FiEdit />
                        </div>
                        </td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}><div className='table_inp'>
                          <input
                           onBlur={(e)=>{
                            let updatedData=[...item?.products]
                            updatedData[i].retail_price = e.target.value;
                            editPro(updatedData[i])
                          }} 
                          onChange={(e) => {
                            let updatedData = [...productDetail?.variation[idx]?.products]; // Assuming productDetail and variation are not null or undefined
                            updatedData[i].retail_price = e.target.value;
                            let updatedVariation = [...productDetail?.variation];
                            updatedVariation[idx].products = updatedData;
                            setProductDetail({...productDetail, variation: updatedVariation});
                          }}
                          value={items?.retail_price} />
                          <FiEdit />
                        </div></td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}>
                          <div className='table_inp'>
                            <input 
                             onBlur={(e)=>{
                              let updatedData=[...item?.products]
                              updatedData[i].purchase_price = e.target.value;
                              editPro(updatedData[i])
                            }} 
                            onChange={(e) => {
                              let updatedData = [...productDetail?.variation[idx]?.products]; // Assuming productDetail and variation are not null or undefined
                              updatedData[i].purchase_price = e.target.value;
                              let updatedVariation = [...productDetail?.variation];
                              updatedVariation[idx].products = updatedData;
                              setProductDetail({...productDetail, variation: updatedVariation});
                            }}
                            value={items?.purchase_price} />
                            <FiEdit />
                          </div>
                        </td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}><div className='table_inp'>
                        {finalValue}
                        </div></td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}} className='text-center'>0</td>
                        <td  className='text-center' style={{ background: `${finalValue > 0 ? "#AAC99E" : "#FF7782"}`, color: "white" }}>
                        {finalValue}
                        </td>
                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}><div className='table_inp'>
                          <input 
                           onBlur={(e)=>{
                            let updatedData=[...item?.products]
                            updatedData[i].min_level = e.target.value;
                            editPro(updatedData[i])
                          }} 
                          onChange={(e) => {
                            let updatedData = [...productDetail?.variation[idx]?.products]; // Assuming productDetail and variation are not null or undefined
                            updatedData[i].min_level = e.target.value;
                            let updatedVariation = [...productDetail?.variation];
                            updatedVariation[idx].products = updatedData;
                            setProductDetail({...productDetail, variation: updatedVariation});
                          }}
                          value={items?.min_level} />
                          <FiEdit />
                        </div></td>

                        <td style={{background: `${isRowSelected(items) ? "#93B1C9" : ""}`,color:`${isRowSelected(items)?"white":""}`}}><Tracked data={items} isRowSelected={isRowSelected}/></td>
                      </tr>
                    )
                  })}
                </>
              )
            })}

            {productDetail?.products?.map((item, i) => {
               const finalValue = item?.pro_inventory?.length
               ? (() => {
                   var value = 0;
                   var resultArray = item.pro_inventory.map((itemss) => {
                     value += itemss?.level || 0;
                     return value;
                   });
                   return resultArray[resultArray.length - 1]; 
                 })()
               : 0;
              return (
                <tr onClick={(e) => getselect(item, e)} style={{display:`${item?.archive===1?"none":""}`,  background: `${isRowSelected(item) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}></td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}} onClick={()=>editModal(item)} className='sku'>{item?.item_no}</td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}>
                    <div className='table_inp'>
                      <input
                       onBlur={(e)=>{
                        let updatedData=[...productDetail?.products]
                        updatedData[i].title = e.target.value;
                        editPro(updatedData[i])
                      }} 
                       onChange={(e)=>{
                        let updatedData=[...productDetail?.products]
                        updatedData[i].title = e.target.value;
                        setProductDetail({...productDetail,products:updatedData})
                      }} 
                      value={item?.title} />
                      <FiEdit />
                    </div>

                  </td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}> <div className='table_inp'>
                    <input
                     onBlur={(e)=>{
                      let updatedData=[...productDetail?.products]
                      updatedData[i].barcode = e.target.value;
                      editPro(updatedData[i])
                    }} 
                     onChange={(e)=>{
                      let updatedData=[...productDetail?.products]
                      updatedData[i].barcode = e.target.value;
                      setProductDetail({...productDetail,products:updatedData})
                    }} 
                    value={item?.barcode} />
                    <FiEdit />
                  </div>
                  </td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}><div className='table_inp'>
                    <input
                     onBlur={(e)=>{
                      let updatedData=[...productDetail?.products]
                      updatedData[i].retail_price = e.target.value;
                      editPro(updatedData[i])
                    }} 
                     onChange={(e)=>{
                      let updatedData=[...productDetail?.products]
                      updatedData[i].retail_price = e.target.value;
                      setProductDetail({...productDetail,products:updatedData})
                    }} 
                    value={item?.retail_price} />
                    <FiEdit />
                  </div></td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}>
                    <div className='table_inp'>
                      <input
                       onBlur={(e)=>{
                        let updatedData=[...productDetail?.products]
                        updatedData[i].purchase_price = e.target.value;
                        editPro(updatedData[i])
                      }} 
                       onChange={(e)=>{
                        let updatedData=[...productDetail?.products]
                        updatedData[i].purchase_price = e.target.value;
                        setProductDetail({...productDetail,products:updatedData})
                      }} 
                      value={item?.purchase_price} />
                      <FiEdit />
                    </div>
                  </td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}><div className='table_inp'>
                    {finalValue}
                  </div></td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}} className='text-center'>0</td>

                  <td className='text-center' style={{ background: `${finalValue > 0 ? "#AAC99E" : "#FF7782"}`, color: "white" }}>
                    {
                     finalValue
                    }
                  </td>
                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}><div className='table_inp'>
                    <input 
                     onBlur={(e)=>{
                      let updatedData=[...productDetail?.products]
                      updatedData[i].min_level = e.target.value;
                      editPro(updatedData[i])
                    }} 
                     onChange={(e)=>{
                      let updatedData=[...productDetail?.products]
                      updatedData[i].min_level = e.target.value;
                      setProductDetail({...productDetail,products:updatedData})
                    }}  value={item?.min_level} />
                    <FiEdit />
                  </div></td>

                  <td style={{background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color:`${isRowSelected(item)?"white":""}`}}><Tracked data={item} isRowSelected={isRowSelected}/></td>
             
                </tr>
              )
            })}


          </tbody>

        </table>
      </div>
    </div>
  )
}

export default TableSection