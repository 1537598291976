import React, { useEffect, useState } from 'react'
import { Add, Close, Delete, Search } from '@mui/icons-material'
import { FiFilter } from 'react-icons/fi'
import { LiaEdit } from 'react-icons/lia'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { LiaSave } from 'react-icons/lia';
import { useStateContext } from '../../Context/ContextProvider'
import { BiSave } from 'react-icons/bi'
import { MdOutlineAddBox } from 'react-icons/md'
import ItemStats from '../Inventory/Itemstats'
const style = {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#F9F9F9',
    boxShadow: 24,
};

function ChildModal({ setproduct, product, choes, setchoes }) {
    const [open, setOpen] = useState(false);
    const { search, searchValue, setSeacrch } = useStateContext()
    const [value, setvalue] = useState("")
    const [showvariation, setshowVariation] = useState(false)
    const [multipulSelct, setmultipulSelct] = useState([])



    let array = choes ? choes : []
    const handleClose = () => {
        setOpen(false);
        setSeacrch([])
    };
    const send = () => {
        // eslint-disable-next-line
        searchValue?.map((item) => {
            // eslint-disable-next-line
            select.filter((items) => {
                if (item.id === items) {
                    array.push(item)
                }
            })
        })
        if (product) {
            // eslint-disable-next-line
            product?.map((items) => {
                array = array?.filter((item) => item.id !== items.id)
            })
            setproduct(array.concat(product))
        }
        else {
            setproduct(array)
            setchoes(array)
        }
        handleClose()
        setselect([])
        setSeacrch([])
    }

    const [select, setselect] = useState([])
    const getselect = (id, event) => {
        if (event.ctrlKey || event.metaKey) {

            setselect([...select, id]);
            setmultipulSelct([...multipulSelct, id])
        } else {
            setselect([id]);
            setmultipulSelct([id])
        }
    }
    const isRowSelected = (id) => select.includes(id);

    const [index, setIndex] = useState()
    const show = (idx) => {
        setIndex(idx)
        setshowVariation(!showvariation)
    }
    const handleOpen = () => {
        setOpen(true);
        setSeacrch([])


    };
    return (
        <React.Fragment>
            <button style={{ border: "1px solid lightgray", padding: ".3rem" }} onClick={handleOpen} className='btnn1'><Add /> Add New</button>
            <Modal
                open={open}
                style={{ overflowY: "scroll" }}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 700, height: "100vh" }}>
                    <div style={{ background: "#44688C", height: "10vh", width: "100%", marginLeft: "0px" }} className='row'>
                        <div className='col-2 p-3'>
                            <button onClick={handleClose} style={{ color: "lightgray", background: "#5C7FA5", border: "none", borderBottom: "1px solid gray" }} className='py-2 px-5'>Back</button>
                        </div>
                        <div className='col-10'>
                            <h4 style={{ color: "white" }} className='p-3'>Stock Finder - Combined stock</h4>
                        </div>
                    </div>
                    <div className='generl_settingvariation2'>

                        <input style={{ width: "94%", border: "1px solid lightgray", padding: ".3rem" }} onChange={(e) => setvalue(e.target.value)} placeholder='Search' />
                        <button style={{ border: "1px solid lightgray", padding: ".2rem .5rem", height: "33px" }} onClick={() => search(value)}><Search /></button>
                    </div>
                    <div className='table_box'>
                        <div style={{ margin: "1rem", height: "70vh", border: "1px solid lightgray", overflow: "scroll" }} className='table-responsive'>

                            <table class="table table-sm">
                                <thead style={{ background: "#F6F6F6" }}>
                                    <tr>
                                        <th style={{ width: "40px" }} scope="col">
                                            <span></span>
                                        </th>
                                        <th scope="col">
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>SKU</div>
                                            </div>

                                        </th>
                                        <th >
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>Title</div>

                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>Level</div>

                                            </div>

                                        </th>
                                        <th scope="col">
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>Availble</div>

                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>In open</div>

                                            </div>

                                        </th>
                                        <th scope="col">
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>Due</div>

                                            </div>

                                        </th>
                                        <th scope="col">
                                            <div className='row'>
                                                <div style={{ whiteSpace: "nowrap" }} className='col-11'>Retail Price</div>

                                            </div>

                                        </th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {searchValue?.map((item, i) => {
                                        return (
                                            <>
                                                <tr onClick={(e) => getselect(item.id, e)} style={{ background: `${isRowSelected(item?.id) ? "#93B1C9" : ""}`, color: `${isRowSelected(item?.id) ? "white" : ""}` }} >
                                                    <td></td>
                                                    <td style={{ color: `${isRowSelected(item?.id) ? "white" : ""}` }}><MdOutlineAddBox onClick={() => show(i)} />  {item?.item_no}</td>
                                                    <td style={{ color: `${isRowSelected(item?.id) ? "white" : ""}` }}><div style={{ width: "100px" }}>{item?.title}</div></td>
                                                    <td style={{ color: `${isRowSelected(item?.id) ? "white" : ""}`, textAlign: "center" }}>{item?.pro_inventory?.level ? item?.pro_inventory?.level : "0"}</td>
                                                    <td style={{ color: `${isRowSelected(item?.id) ? "white" : ""}`, background: `${item?.pro_inventory?.level > 0 ? "#AAC99E" : "#FF7782"}`, textAlign: "center" }}>{item?.pro_inventory?.level ? item?.pro_inventory?.level : "0"}</td>

                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td>{item.retail_price}</td>

                                                </tr>
                                                {showvariation && index === i ? <tr >
                                                    <td colspan="8" style={{ width: "100%" }}>
                                                        <div className='variation_innerState'>
                                                            <ItemStats data={item} />

                                                        </div>
                                                    </td>

                                                </tr> : ""}
                                            </>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='d-flex justify-content-end'>
                        <button style={{ padding: ".3rem", border: "1px solid lightgray" }} onClick={send} className='mx-3'><BiSave /> Save</button>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

function Variation() {
    const [open, setOpen] = useState(false);
    const { getVariation, variationData, editVariation, createVraiation, delVariation } = useStateContext()
    const [product, setproduct] = useState([])
    const [edit, setedit] = useState(false)
    const [multipulSelct, setmultipulSelct] = useState([])
    const [choes, setchoes] = useState([])

    const [select, setselect] = useState([])
    const getselect = (id, event) => {
        if (event.ctrlKey || event.metaKey) {

            setselect([...select, id]);
            setmultipulSelct([...multipulSelct, id])
        } else {
            setselect([id]);
            setmultipulSelct([id])
        }
    }
    const isRowSelected = (id) => select.includes(id);
    const [data, setdata] = useState({
        sku: "",
        name: "",
        products: product
    })
    useEffect(() => {
        setdata({ ...data, products: product })
        // eslint-disable-next-line
    }, [product])
    const handleOpen = () => {
        setOpen(true);
        setchoes([])
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        getVariation()
        // eslint-disable-next-line
    }, [])
    const openedit = (data) => {
        setproduct(data.products)
        handleOpen()
        setdata(data)
        setedit(true)
    }
    const addVariation = () => {
        setedit(false)
        setproduct([])
        handleOpen()
    }
    const delProduct = (id) => {
        // eslint-disable-next-line
        const newdata = product?.filter((item) => {
            if (item.id !== id) {
                return item
            }
        })
        setproduct(newdata)
        setchoes(newdata)
    }
    const save = () => {
        createVraiation(data)
        setdata({})
        setproduct([])
        handleClose()
    }
    const edtevariation = () => {
        const obj = {
            sku: data.sku,
            name: data.name,
            products: product,
            id: data.id
        }
        editVariation(obj)
        handleClose()
    }
    return (
        <div className='variation'>
            <div className='row'>
                <div className='col-9'>
                    <select>
                        <option>Variation Name</option>
                        <option>Item SKU</option>
                        <option>Variation SKU</option>
                    </select>
                    <input />
                    <button className='mx-2'><Search /></button>
                </div>
                <div style={{display:"flex",justifyContent:"flex-end",flexWrap:"wrap",alignItems:"flex-end"}} className='col-3'>
                    <button onClick={addVariation} className='btnn1'><Add /> Add New</button>
                    <Modal
                        open={open}
                        style={{ overflowY: "scroll" }}
                        onClose={handleClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        {edit ? <Box sx={{ ...style, width: 700, height: "130vh" }}>
                            <div style={{ background: "#44688C", height: "10vh", width: "100%", marginLeft: "0px" }} className='row'>
                                <div className='col-11'>
                                    <h4 style={{ color: "white" }} className='p-3'>Edit Variation Group</h4>
                                </div>
                                <div className='col-1 p-3'>
                                    <button style={{ color: "lightgray", background: "none", border: "none" }} onClick={handleClose}><Close /></button>
                                </div>
                            </div>
                            <div className='generl_settingvariation'>
                                <h6>General Settings:</h6>
                                <div className='row'>
                                    <div className='col-3'>
                                        <lable>Variation SKU</lable>
                                    </div>
                                    <div className='col-9'>
                                        <input value={data.sku} disabled style={{ cursor: "not-allowed" }} />

                                    </div>
                                    <div className='col-3'>
                                        <lable>Variation Name</lable>
                                    </div>
                                    <div className='col-9'>
                                        <input value={data.name} onChange={(e) => setdata({ ...data, name: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: "none" }} className='generl_settingvariation'>
                                <div className='row'>
                                    <div className='col-9'>
                                        <h6>Products:</h6>

                                    </div>
                                    <div className='col-3'>
                                        <ChildModal product={product} setproduct={setproduct} />

                                    </div>

                                </div>
                            </div>
                            <div className='table_box'>
                                <div style={{ margin: "0 1rem", height: "70vh", border: "1px solid lightgray", overflow: "scroll" }} className='table-responsive'>

                                    <table class="table table-sm">
                                        <thead style={{ background: "#F6F6F6" }}>
                                            <tr>

                                                <th scope="col">
                                                    <div className='row'>
                                                        <div className='col-11'>SKU</div>

                                                    </div>

                                                </th>
                                                <th scope="col">
                                                    <div className='row'>
                                                        <div className='col-11'>Title</div>

                                                    </div>
                                                </th>
                                                <th style={{ width: "40px" }} scope="col">
                                                    <span></span>
                                                </th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product?.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>{item?.item_no}</td>
                                                        <td>{item?.title}</td>
                                                        <td onClick={() => delProduct(item?.id)}><Delete /></td>



                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className='d-flex justify-content-end'>
                                <button onClick={edtevariation} className='save_btn_variation my-3 mx-2'><LiaSave className='mx-2' />Save</button>
                                <button className='cancel_btn_variation m-3'>Canacl</button>
                            </div>

                        </Box> : <Box sx={{ ...style, width: 700, height: "130vh" }}>
                            <div style={{ background: "#44688C", height: "10vh", width: "100%", marginLeft: "0px" }} className='row'>
                                <div className='col-11'>
                                    <h4 style={{ color: "white" }} className='p-3'>Add Variation Group</h4>
                                </div>
                                <div className='col-1 p-3'>
                                    <button style={{ color: "lightgray", background: "none", border: "none" }} onClick={handleClose}><Close /></button>
                                </div>
                            </div>
                            <div className='generl_settingvariation'>
                                <h6>General Settings:</h6>
                                <div className='row'>
                                    <div className='col-3'>
                                        <lable>Variation SKU</lable>
                                    </div>
                                    <div className='col-9'>
                                        <input onChange={(e) => setdata({ ...data, sku: e.target.value })} />

                                    </div>
                                    <div className='col-3'>
                                        <lable>Variation Name</lable>
                                    </div>
                                    <div className='col-9'>
                                        <input onChange={(e) => setdata({ ...data, name: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ border: "none" }} className='generl_settingvariation'>
                                <div className='row'>
                                    <div className='col-9'>
                                        <h6>Products:</h6>

                                    </div>
                                    <div className='col-3'>
                                        <ChildModal choes={choes} setchoes={setchoes} setproduct={setproduct} />

                                    </div>

                                </div>
                            </div>
                            <div className='table_box'>
                                <div style={{ margin: "0 1rem", height: "70vh", border: "1px solid lightgray", overflow: "scroll" }} className='table-responsive'>

                                    <table class="table table-sm">
                                        <thead style={{ background: "#F6F6F6" }}>
                                            <tr>

                                                <th scope="col">
                                                    <div className='row'>
                                                        <div className='col-11'>SKU</div>

                                                    </div>

                                                </th>
                                                <th scope="col">
                                                    <div className='row'>
                                                        <div className='col-11'>Title</div>
                                                    </div>
                                                </th>
                                                <th style={{ width: "40px" }} scope="col">
                                                    <span></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product?.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>{item?.item_no}</td>
                                                        <td>{item?.title}</td>
                                                        <td onClick={() => delProduct(item?.id)}><Delete /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className='d-flex justify-content-end'>
                                <button onClick={save} className='save_btn_variation my-3 mx-2'><LiaSave className='mx-2' />Save</button>

                                <button onClick={handleClose} className='cancel_btn_variation m-3'>Canacl</button>
                            </div>

                        </Box>}
                    </Modal>
                    <button className='btnn' onClick={() => delVariation(select)}><Delete /> Delete</button>
                </div>
            </div>
            <div className='table_box'>
                <div className='table-responsive'>

                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th style={{ width: "40px" }} scope="col">
                                    <span></span>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-11'>Variation SKU</div>
                                        <div className='col-1'><FiFilter /></div>
                                    </div>

                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-11'>Variation Name</div>
                                        <div className='col-1'><FiFilter /></div>
                                    </div>
                                </th>
                                <th style={{ width: "40px" }} scope="col">
                                    <span></span>
                                </th>


                            </tr>
                        </thead>
                        <tbody>
                            {variationData?.variations?.map((item) => {
                                return (
                                    <tr onClick={(e) => getselect(item.id, e)} style={{ background: `${isRowSelected(item?.id) ? "#93B1C9" : ""}`, color: `${isRowSelected(item?.id) ? "white" : ""}` }}>
                                        <td></td>
                                        <td>{item.sku}</td>
                                        <td>{item.name}</td>
                                        <td onClick={() => openedit(item)}><LiaEdit style={{ fontSize: "22px" }} /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Variation