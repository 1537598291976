import React from 'react'
import Avatar from '@mui/material/Avatar';
import logo from '../../Image/linnworks.png'
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { Link, Route, Routes } from 'react-router-dom';
import Categories from '../Category/Categories';
import Location from '../Location/Location';
import Suppliers from '../Supplier/Suppliers';
import Inventory from '../../Pages/Inventory';
import Variation from '../Variation/Variation';
import StockCount from '../../Pages/Stockcount';
import ScrabItems from '../../Pages/ScrabItems';
import StockIn from '../../Pages/StockIn';
import AmazonVendor from '../../Pages/AmazonVendor';
import ParchaseOrder from '../../Pages/ParchaseOrder';
import CreateNewOp from '../../Pages/CreateNewOp';
import EditPO from '../../Pages/EditPO';
import CircularIndeterminate from '../Loding/CircularIndeterminate';
import { useStateContext } from '../../Context/ContextProvider';
import { BsQuestionCircleFill } from 'react-icons/bs';
import WareHouseTransfer from '../../Pages/WareHouseTransfer';
import NewStockTransfer from '../../Pages/NewStockTransfer';
import Transferstatus from '../../Pages/Transferstatus';
import StartRecuving from '../../Pages/StartRecuving';
import MarkRecived from '../../Pages/MarkRecived';
import ViewTransfer from '../../Pages/ViewTransfer';
import SnakBar from '../Alert/SnakBar';
function SidaBar() {
    const { loading, setLogin, userEmail } = useStateContext()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate()
    const logout = () => {
        setLogin("")
        localStorage.removeItem("user_token")
        navigate("/")
        handleClose()
    }
    return (
        <div style={{ display: "flex" }} className='main_sideBar'>

            <div className='side_bar'>
                <img src={logo} alt='...' />
                <div className="btn-group dropright">
                    <button type="button" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/* <FaCubes className="iocns"/> */}
                        Inventory
                    </button>
                    <div style={{ width: "500px", borderRadius: "0px", background: "#222B36", height: "400px", color: "white" }} className="dropdown-menu">
                        <div style={{ padding: "0px", margin: "0px" }} className='row'>
                            <div className='col-6'>
                                <h5 style={{ marginLeft: "20px" }}>Inventory</h5>
                                <ul>
                                    <Link className="link" to={'/app/MyInventory'}>  <li><p>My Inventory</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span></li></Link>
                                    <Link className='link' to={'/app/WarehouseTransfer'}>
                                        <li><p>Warehouse transfer <span>New</span></p> <span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span></li>
                                    </Link>
                                    <li><p>Reorder Low Stock <span>New</span></p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    <Link to={'/'} className='link'>
                                        <li><p>FBA Stock Dashboard <span>New</span></p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/vendor/order-view'} className='link'>
                                        <li><p>Amazon Vendor Central</p> </li>
                                    </Link>
                                    <Link to={'/app/PurchaseOrder'} className='link'>
                                        <li><p>Purchase orders</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/StockIn'} className='link'>
                                        <li><p>Stock In</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/StockCount'} className='link'>
                                        <li><p>Stock count</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/ScrapItems'} className='link'>
                                        <li><p>Scrap items</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>

                                </ul>
                            </div>
                            <div className='col-6'>
                                <h5 style={{ marginLeft: "20px" }}>Inventory Setting</h5>
                                <ul>
                                    <Link to={'/app/StockVariations'} className="link">

                                        <li><p>Variations</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/Categories'} className="link">
                                        <li><p>Categories</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/Locations'} className="link">
                                        <li><p>Locations</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>
                                    <Link to={'/app/SuppliersRegister'} className="link">
                                        <li><p>Suppliers</p><span><BsQuestionCircleFill style={{ color: "lightgray" }} /></span> </li>
                                    </Link>


                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className='main_Section'>
                <div className='top_bar'>
                    <div className='row'>
                        <div className='col-9'></div>
                        <div className='col-3'>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <Tooltip style={{ borderRadius: "50px", width: "100%" }} title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        // sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar sx={{ background: "#4A6BDB", width: 25, height: 25, fontSize: "12px" }}>D</Avatar>
                                        <Typography sx={{ minWidth: 100, marginLeft: "5px" }}>{userEmail}</Typography>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <Avatar /> Profile
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Avatar /> My account
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Add another account
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem>
                                <MenuItem onClick={logout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>

                </div>
                <div className='pages'>
                    {loading && <div className='loding'>
                        <div className='d-flex justify-content-center align-items-center h-100' >
                            <CircularIndeterminate />

                        </div>
                    </div>}
                    <Routes>
                        <Route path='/Categories' element={<Categories />} />
                        <Route path='/Locations' element={<Location />} />
                        <Route path='/SuppliersRegister' element={<Suppliers />} />
                        <Route path='/MyInventory' element={<Inventory />} />
                        <Route path='/StockVariations' element={<Variation />} />
                        <Route path='/StockCount' element={<StockCount />} />
                        <Route path='/ScrapItems' element={<ScrabItems />} />
                        <Route path='/StockIn' element={<StockIn />} />
                        <Route path='/vendor/order-view' element={<AmazonVendor />} />
                        <Route path='/PurchaseOrder' element={<ParchaseOrder />} />
                        <Route path='/EditPurchaseOrder' element={<CreateNewOp />} />
                        <Route path='/EditPurchaseOrder/:id' element={<EditPO />} />
                        <Route path='/WarehouseTransfer' element={<WareHouseTransfer />} />
                        <Route path='/StockTransfer' element={<NewStockTransfer />} />
                        <Route path='/transfer/:id' element={<Transferstatus />} />
                        <Route path='/startreceving/:id' element={<StartRecuving />} />
                        <Route path='/markrecived/:id' element={<MarkRecived />} />
                        <Route path='/recived/:id' element={<ViewTransfer />} />
                    </Routes>
                </div>
            </div>


            <SnakBar/>

        </div>
    )
}

export default SidaBar