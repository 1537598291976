import { createContext, useContext, useState } from "react";
const StateContext = createContext()
export const ContextProvider = ({ children }) => {
  const base_url = "https://linworks.azeetechnology.com/admin/public/api/"
  const imgBase_url = "https://linworks.azeetechnology.com/admin/public/"
  const [loginData, setLogin] = useState()
  const auth = JSON.parse(localStorage.getItem("user_token")) ? JSON.parse(localStorage.getItem("user_token")) : loginData
  const token = `Bearer ${auth?.token}`
  const userEmail=JSON.parse(localStorage.getItem("user_token"))?.user?.email
  const [allCetagore, setallCetagore] = useState([])
  const [multipulSelct, setmultipulSelct] = useState([])
  const [supplier, setsuplier] = useState([])
  const [state, setState] = useState(false);

  const [snackbarValue, setSnackbarValue] = useState({
    message: "",
    color: ""
  })
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setState(true);
  }

  const login = async (obj) => {
    fetch(`${base_url}login`, {
      method: 'POST',
      // mode:"no-cors",
      headers: {
        'Content-Type': 'application/json',
        "Accept": 'application/json',
      },
      body: JSON.stringify(obj)
    })
      .then(response => response.json())
      .then(data => {
        if(data?.token){
          setLogin(data)
          setLoading(true)
          handleClick()
          snackbarValue({
            message: "Email or Password Invalid !",
            color: "#FF7782"
          })
        }
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const getCategory = () => {
    setLoading(true)
    fetch(`${base_url}categories`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {
        setallCetagore(resp);
        setLoading(false)
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const deletCategory = (id) => {
    fetch(`${base_url}categories`, {
      method: "DELETE",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        category: id
      })
    })
      .then(res => res.json())
      .then(resp => {

        if (resp?.status) {
          getCategory()
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Delete successfully !"
          })
        }
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const editCategory = (obj) => {
    fetch(`${base_url}categories/${obj?.id}?name=${obj?.name}`, {
      method: "PUT",
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        if (resp?.status) {
          const newdata = allCetagore.filter((item) => {
            if (item.id === obj?.id) {
              item.name = obj?.name
              return item
            }
            else {
              return item
            }
          })
          setallCetagore(newdata)
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Edit successfully !"
          })
        }


      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }

  const addCategory = async (obj) => {
    setLoading(true)

    fetch(`${base_url}categories`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {

        setLoading(false)
        if (resp?.status) {
          setallCetagore([...allCetagore, resp.category])
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Create successfully !"
          })
        }
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });

  }
  const getsuplier = () => {
    setLoading(true)

    fetch(`${base_url}suppliers`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setsuplier(resp)
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const createSuplier = (obj) => {
    setLoading(true)

    fetch(`${base_url}suppliers`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {

        setsuplier([...supplier, resp])
        setLoading(false)
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Created successfully !"
        })

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [currencyList, setcurrencyList] = useState([])
  const currency = () => {
    fetch(`${base_url}currencies`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setcurrencyList(resp)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const delsupplier = (id) => {
    setLoading(true)
    fetch(`${base_url}suppliers`, {
      method: "DELETE",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        supplier_ids: id
      })

    })
      .then(res => res.json())
      .then(resp => {

        if (resp) {
          // setsuplier(newddata)
          getsuplier()
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Deleted successfully !"
          })
        }


      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const editsupplier = (obj) => {
    setLoading(true)

    fetch(`${base_url}suppliers/${obj.id}`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)

    })
      .then(res => res.json())
      .then(resp => {

        // getPObyId(obj.po_id)
        getsuplier()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Edit successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [wareHouse, setwareHouse] = useState([])

  const WareHouse = () => {
    setLoading(true)

    fetch(`${base_url}warehouse`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setwareHouse(resp)
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const editWareHouse = (obj) => {
    setLoading(true)

    fetch(`${base_url}warehouse/${obj?.id}`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Edit successfully !"
        })
        WareHouse()
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const createWareHouse = (obj) => {
    fetch(`${base_url}warehouse`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {
        WareHouse()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const deletWarehouse = (id) => {
    setLoading(true)

    fetch(`${base_url}warehouse-delete`, {
      method: "DELETE",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        warehouses: id
      })
    })
      .then(res => res.json())
      .then(resp => {

        WareHouse()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Deleted successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [opens, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [sku, setsku] = useState()
  const getSku = () => {
    setLoading(true)


    fetch(`${base_url}generate-sku`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setsku(resp)
        handleOpen()
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [productDetail, setProductDetail] = useState({})
  const createProduct = (proData) => {
    setLoading(true)

    fetch(`${base_url}prodetails`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(proData)
    })
      .then(res => res.json())
      .then(resp => {

        getProduct()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const coloum = ["", 'SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Stock Level', 'In Open Orders', 'Availble', 'Minimum Level', 'Tracked']

  const getProduct = () => {
    fetch(`${base_url}prodetails`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {
        setProductDetail({
          coloum: coloum,
          variation: resp?.variations,
          products: resp?.single_products
        })
        setLoading(false)
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const getViewId = (id) => {
    setLoading(true)
    fetch(`${base_url}saveViewAndFetchProducts`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        view_id: id
      })

    })
      .then(res => res.json())
      .then(resp => {
        let colu = []
        colu = JSON.parse(resp?.view?.columns)
        var sorted = coloum.filter((item) => {
          return colu.includes(item)
        })
        sorted.unshift(" ")
        setProductDetail({
          coloum: sorted,
          variation: resp?.products?.variations,
          products: resp?.products?.single_products
        })
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const editPro = (data) => {
    setLoading(true)

    fetch(`${base_url}prodetails/${data.id}`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(data)

    })
      .then(res => res.json())
      .then(resp => {
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Edit successfully !"
        })
        getProduct()
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [allview, setallvew] = useState()
  const getallView = () => {
    fetch(`${base_url}user-views`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(res => res.json())
      .then(resp => {

        setallvew(resp)
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }

  const [source, setsource] = useState()
  const getsource = () => {
    fetch(`${base_url}sources`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setsource(resp)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });

  }
  const [subsource, setsubsource] = useState()
  const getsubsource = () => {
    fetch(`${base_url}subsources`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setsubsource(resp)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });

  }
  const expoort = (obj) => {
    let object = {
      delimiter: obj.delimiter,
      encoding: obj.encoding,
      exportType: obj.exportType,
      selectedColumns: obj.selectedColumns,
      productIds: multipulSelct
    }
    fetch(`${base_url}export-csv`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(object)
    })
      .then(res => res.blob())
      .then(resp => {
        const csvUrl = URL.createObjectURL(resp);

        const link = document.createElement('a');
        link.href = csvUrl;
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // setVariation(resp)
        // getVariation()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Export successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [variationData, setVariation] = useState([])
  const getVariation = () => {
    setLoading(true)
    fetch(`${base_url}variations`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setVariation(resp)
        setLoading(false)
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const editVariation = (obj) => {
    setLoading(true)
    fetch(`${base_url}variations/${obj.id}`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {

        if (resp.message === "Variation and products updated successfully") {
          getVariation()
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Edit successfully !"
          })
        }


      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [searchValue, setSeacrch] = useState()
  const search = (value) => {
    if (value) {
      setLoading(true)

      fetch(`${base_url}variations/search-products?query=${value}`, {
        headers: {
          'Authorization': token,
        }
      })
        .then(res => res.json())
        .then(resp => {

          setSeacrch(resp)
          setLoading(false)

        })
        .catch(error => {
          // Handle the error here
          console.error('Error fetching data:', error);
          // You might want to set an error state or show an error message to the user
        });
    }

  }
  const createVraiation = (obj) => {
    setLoading(true)

    fetch(`${base_url}variations`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {

        getVariation()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }

  const delVariation = (id) => {
    if (id) {
      setLoading(true)

      fetch(`${base_url}variations`, {
        method: "DELETE",
        headers: {
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ ids: id })
      })
        .then(res => res.json())
        .then(resp => {

          if (resp?.message === 'Variations deleted successfully') {
            getVariation()
            handleClick()
            setSnackbarValue({
              color:"#31A07E",
              message:"Delated successfully !"
            })
          }


        })
        .catch(error => {
          // Handle the error here
          console.error('Error fetching data:', error);
          // You might want to set an error state or show an error message to the user
        });
    }

  }
  const [allScrabcategory, setScrabCategory] = useState([])
  const addScrabCategory = async (obj) => {
    setLoading(true)

    fetch(`${base_url}scrap-categories`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {

        allscrabcategory()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });

  }
  const allscrabcategory = () => {

    fetch(`${base_url}scrap-categories`, {

      headers: {

        'Authorization': token,
      },

    })
      .then(res => res.json())
      .then(resp => {

        setLoading(false)

        setScrabCategory(resp)
      })
  }
  const deletScrabCategory = (id) => {
    setLoading(true)

    fetch(`${base_url}scrap-categories/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        if (resp) {
          // eslint-disable-next-line
          const newddata = allScrabcategory.filter((item) => {
            if (item.id !== id) {
              return item
            }
          })
          // eslint-disable-next-line
          setScrabCategory(newddata)
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Delete successfully !"
          })
        }
        else {

        }

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [scrabItem, setScrabItem] = useState()
  const scrabFunction = () => {
    setLoading(true)

    fetch(`${base_url}scrap-items`, {

      headers: {
        'Authorization': token,
      },

    })
      .then(res => res.json())
      .then(resp => {


        setScrabItem(resp)
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const createscrabFunction = (obj) => {
    setLoading(true)

    fetch(`${base_url}scrap-items`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)

    })
      .then(res => res.json())
      .then(resp => {

        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })
        scrabFunction()

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const stock = (obj) => {
    setLoading(true)

    fetch(`${base_url}update-stock-level`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)

    })
      .then(res => res.json())
      .then(resp => {
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Stock Create successfully !"
        })
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [poData, setPOdata] = useState([])
  const getPO = () => {
    setLoading(true)

    fetch(`${base_url}purchase-orders`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setPOdata(resp)
        setLoading(false)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [singelPo, setSingalPO] = useState()
  const getPObyId = (id) => {
    setLoading(true)
    fetch(`${base_url}purchase-orders/${id}`, {

      headers: {

        'Authorization': token,
      },
    })
      .then(res => res.json())
      .then(resp => {

        setSingalPO(resp)
        setLoading(false)
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const deletePO = (id) => {
    if (id) {
      setLoading(true)
      fetch(`${base_url}purchase-orders/${id}`, {
        method: "DELETE",
        headers: {
          'Authorization': token,
        }
      })
        .then(res => res.json())
        .then(resp => {
          if (resp) {
            setLoading(false)
            handleClick()
            setSnackbarValue({
              color:"#31A07E",
              message:"Delated successfully !"
            })
          }
        })
        .catch(error => {
          // Handle the error here
          console.error('Error fetching data:', error);
          // You might want to set an error state or show an error message to the user
        });
    }

  }
  const createPo = (obj) => {
    fetch(`${base_url}purchase-orders`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {


        getVariation()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const [getTrasferData, setgetTrasferdata] = useState([])
  const getTrasfer = () => {
    fetch(`${base_url}get-transfers`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        setgetTrasferdata(resp)

      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const createnote = (obj) => {
    fetch(`${base_url}ponotes`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)

    })
      .then(res => res.json())
      .then(resp => {
        getPObyId(obj.po_id)
        // getinventoryFun()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const delPONote = (id, poID) => {
    fetch(`${base_url}ponotes/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {
        // getPObyId(obj.po_id)
        getPObyId(poID)
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Delated successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const createExtantPO = (obj) => {
    const data = {
      name: obj[0]?.name,
      date: obj[0]?.date,
      po_id: obj[0]?.po_id,
      value: obj[0]?.value,
      vendor_id: obj[0]?.vendor_id,
      email: obj[0]?.email,
      time: obj[0]?.time

    }
    fetch(`${base_url}purchase-orders-extend`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(data)

    })
      .then(res => res.json())
      .then(resp => {
        getPObyId(data.po_id)
        // getinventoryFun()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const changePOstatus = (obj) => {
    fetch(`${base_url}purchase-order/${obj.id}/update-status`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        status: obj.status
      })
    })
      .then(res => res.json())
      .then(resp => {
        if (resp?.message === "Status updated successfully!") {
          getPObyId(obj.id)
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Status updated successfully !"
          })
        }

      }).catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const editPo = (obj) => {
    fetch(`${base_url}purchase-orders/${obj.id}`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {
        getPObyId(obj.id)
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Edit successfully !"
        })
      })
      .catch(error => {
        // Handle the error here
        console.error('Error fetching data:', error);
        // You might want to set an error state or show an error message to the user
      });
  }
  const getallTransfer = (id) => {
    if (id === 0) {
      getTrasfer()
    }
    else {
      fetch(`${base_url}get-transfers?warehouse_id=${id}`, {
        headers: {
          'Authorization': token,
        },
      })
        .then(res => res.json())
        .then(resp => {
          setgetTrasferdata(resp)
        })
    }
  }
  const [transferData, setTransferData] = useState([])
  const wareTransferData = (obj) => {
    fetch(`${base_url}product-count-by-warehouse/${obj?.ship_from}/${obj?.ship_destination}`, {
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {
        setTransferData(resp?.product_details)


      })
  }
  const createTranfer = (obj) => {
    fetch(`${base_url}transfers`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Create successfully !"
        })
      })
  }
  const [allTransfer, setallTransfer] = useState()
  const getTransferDetail = (id) => {
    fetch(`${base_url}transfers/${id}`, {
      headers: {
        'Authorization': token,
      },
    })
      .then(res => res.json())
      .then(resp => {
        setallTransfer(resp)
      })

  }
  const transferStatus = (obj, status) => {
    let array = []
    let newobj = {}
    console.log(obj);
    // eslint-disable-next-line
    obj.products?.filter((item) => {
      var newdata = {}
      if (status === "receiving") {
        newdata = {
          product_id: item?.product?.id,
          received_quantity: parseInt(item?.quantity_in_transfer)
        }
        array.push(newdata)
      }
      else if (status === "in transit") {
        newdata = {
          product_id: item?.product?.id,
          quantity: parseInt(item?.quantity_in_transfer)
        }
        array.push(newdata)
      }
      else if (status === "received") {
        newdata = {
          product_id: item?.product?.id,
          received_quantity: parseInt(item?.quantity_in_transfer)
        }
        array.push(newdata)
      }
    })
    newobj = {
      transfer_id: obj?.transfer_id,
      products: array,
      status: status,
      to_warehouse_id: obj?.to_warehouse_id,
      from_warehouse_id: obj?.from_warehouse_id,
    }
    fetch(`${base_url}transfers/status`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(newobj)
    })
      .then(res => res.json())
      .then(resp => {
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Status Update successfully !"
        })
      })
  }
  const delView = (id) => {
    fetch(`${base_url}user-views/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {

        if (resp) {
          getallView()
          getProduct()
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Delated successfully !"
          })
        }


      })
  }
  const updateVariation = (value1) => {
    fetch(`${base_url}variations-update/${value1?.id}`, {
      method: "PUT",

      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(value1)
    })
      .then(res => res.json())
      .then(resp => {
        getProduct()
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Edit successfully !"
        })
      })
  }
  const prodelete = (id) => {
    console.log(id);
    if (id) {
      fetch(`${base_url}products/multiple-delete`, {
        method: "DELETE",
        headers: {
          'Content-Type': "application/json",
          "Accept": 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          product_ids: id
        })
      })
        .then(res => res.json())
        .then(resp => {
          getProduct()
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Delated successfully !"
          })
        })
    }
  }
  const trackedFun = async (obj) => {

    fetch(`${base_url}product/setTracked`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(resp => {
        getProduct()


      })

  }
  const createView = (obj) => {
    fetch(`${base_url}saveViewAndFetchProducts`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)

    })
      .then(res => res.json())
      .then(resp => {
        if (resp) {
          getallView()
          handleClick()
          setSnackbarValue({
            color:"#31A07E",
            message:"Create  successfully !"
          })
        }
      })
  }
  const viewEdit = (obj, id) => {
    fetch(`${base_url}user-views/${id}`, {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(obj)

    })
      .then(res => res.json())
      .then(resp => {
        getViewId(id)
        handleClick()
        setSnackbarValue({
          color:"#31A07E",
          message:"Edit successfully !"
        })
      })
  }
  const archive=(id)=>{
    fetch(`${base_url}prodetails/${id}/archive`,{
      method: "PUT",
      headers: {
        
          'Authorization': token,
      },
      
    
    })
    .then(res => res.json())
    .then(resp => {
    console.log(resp);
    // getPObyId(obj.po_id)
    getProduct()
    })
   }
  const getByCategory=(id)=>{
    console.log(id);
    if(id==='0'){
      fetch(`${base_url}prodetails`,{
        headers:{
          'Authorization': token,
        }
      })
          .then(res => res.json())
          .then(resp => {
  
 
            setProductDetail({
              coloum: coloum,
              variation: resp?.variations,
              products: resp?.single_products
            })
         
          })
    }
    else{
      fetch(`${base_url}prodetails`,{
        headers:{
          'Authorization': token,
        }
      })
          .then(res => res.json())
          .then(resp => {
// eslint-disable-next-line     
      const newdata=resp?.single_products?.filter((item)=>{
        if(item.category===parseInt(id)){
          return item
        }
      })
      setProductDetail({
        coloum: coloum,
        variation: resp?.variations,
        products: newdata,
      })
          })
    }
  

  }
  const searchBy=(value)=>{
    fetch(`${base_url}products/search?query=${value}`,{
      headers:{
        'Authorization':token,
      }
    })
    .then(res => res.json())
    .then(resp => {
    const variation=productDetail?.variation
    setProductDetail({
      coloum: coloum,
      variation: variation,
      products: resp
    })
    
    })
  }
  const filter=(id)=>{
if(id==='0'){
getProduct()
}else{

  fetch(`${base_url}products/count-by-warehouse/${id}`,{
    headers:{
      'Authorization': token,
    }
  })
      .then(res => res.json())
      .then(resp => {

        setProductDetail({
          coloum: coloum,
          variation: resp?.variations,
          products: resp?.single_products,
        })
                  
  
     
      })
}
  

  
}
  return (
    <StateContext.Provider value={{
      login,
      state,
      filter,
      imgBase_url,
      loginData,
      getCategory,
      allCetagore,
      editCategory,
      deletCategory,
      addCategory,
      archive,
      supplier,
      getsuplier,
      createSuplier,
      delsupplier,
      currencyList,
      currency,
      editsupplier,
      WareHouse,
      editWareHouse,
      wareHouse,
      createWareHouse,
      deletWarehouse,
      snackbarValue,
      setState,
      getSku,
      setOpen,
      sku,
      opens,
      getProduct,
      productDetail,
      setProductDetail,
      editPro,
      getallView,
      allview,
      getViewId,
      createProduct,
      getsubsource,
      getsource,
      source,
      subsource,
      getVariation,
      variationData,
      editVariation,
      createVraiation,
      delVariation,
      search,
      searchValue,
      setSeacrch,
      multipulSelct,
      setmultipulSelct,
      expoort,
      addScrabCategory,
      createscrabFunction,
      scrabFunction,
      scrabItem,
      allscrabcategory,
      deletScrabCategory,
      allScrabcategory,
      stock,
      getPO,
      poData,
      loading,
      getPObyId,
      singelPo,
      deletePO,
      createPo,
      getTrasfer,
      getTrasferData,
      createnote,
      delPONote,
      createExtantPO,
      changePOstatus,
      editPo,
      getallTransfer,
      wareTransferData,
      transferData,
      createTranfer,
      getTransferDetail,
      allTransfer,
      transferStatus,
      delView,
      updateVariation,
      prodelete,
      trackedFun,
      createView,
      viewEdit,
      getByCategory,
      searchBy,
      userEmail,
      setLogin

    }}>
      {children}
    </StateContext.Provider>
  )
}
export const useStateContext = () => useContext(StateContext)