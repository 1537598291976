import { Divider } from '@mui/material'
import React from 'react'
import { useStateContext } from '../../Context/ContextProvider';

function ImageSection({ data, setdata }) {
    const { imgBase_url } = useStateContext()
    const handleImageChange = (e) => {
        const files = e.target.files;
        console.log(files);
        if (files.length > 0) {
            const promises = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const promise = new Promise((resolve) => {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const base64String = event.target.result;
                        resolve({ file, base64String });
                    };

                    reader.readAsDataURL(file);
                });

                promises.push(promise);
            }

            Promise.all(promises).then((results) => {
                const updatedData = results.map(({ file, base64String }) => ({

                    base64String,
                }));
                console.log(updatedData);
                updatedData?.forEach((item) => {
                    setdata((prevData) => ({
                        ...prevData,
                        images: [...prevData.images, item.base64String],
                    }));
                });
            });
        }
        else {

        }
    };
    const delfun = (index) => {
        const updatedData = data?.images?.filter((item, idx) => {
            return idx !== index;
        });
        setdata({ ...data, images: updatedData });
    };
    console.log(data);
    return (
        <div className='inv_tabInp_main'>
            <label for="inv_imga" style={{ background: "#E9E9E9", padding: ".5rem 1rem", color: "gray", margin: ".5rem" }}>Add Image</label>
            <input onChange={(e) => handleImageChange(e)} type='file' style={{ display: "none" }} id='inv_imga' />
            <button style={{ padding: ".5rem .8rem", border: "none" }} className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a href='...' className="dropdown-item">
                    <label for="inv_imga">Upload Image From Computer</label>
                </a>
            </div>
            <Divider style={{ margin: "0 .5rem" }} />
            <div className='p-2 d-flex flex-wrap'>

                {data?.images?.map((item, idx) => {
                    return (
                        <div style={{ width: "200px", height: "150px", border: "1px solid lightgray", padding: ".4rem", marginLeft: "10px", marginTop: "10px" }}>
                            <div style={{ height: "15%"}} className='d-flex justify-content-between'>
                                {idx===0&&<span>Main</span>}
                                <span style={{ cursor: "pointer" }} onClick={() => delfun(idx)}>X</span>
                            </div>

                            <div style={{ width: "100%", height: "85%", border: "1px solid lightgray", padding: ".4rem" }}>

                                <img style={{ width: "100%", height: "100%" }} src={item?.image_path ? imgBase_url + item?.image_path : item} alt='...' />
                            </div>
                        </div>
                    )
                })}
            </div>



        </div>
    )
}

export default ImageSection