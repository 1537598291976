import React from 'react'
import { IoIosAdd } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'

function Composition() {
  return (
    <div className='list_main'>

    <div style={{ height: "max-content" }} className='inv_tabInp_main'>
    <div className='row'>
        <div className='col-6'>
<h5 className='p-2' style={{ color: '#2895d8' }} >Composition:</h5>

        </div>
        <div  className='col-6 d-flex justify-content-end'>
            <div>

            <button className='btn_listAction'><IoIosAdd/> Add New</button>
            <button className='btn_listAction mx-2'><RiDeleteBin5Line/> Delete</button>
            </div>
        </div>
    </div>
<div className='list_table'  style={{ padding: ".4rem" }}>
<table className="table table-sm">
<thead>
<tr>
  <th scope="col">SKU</th>
  <th scope="col">Item Title</th>
  <th scope="col">Quantity</th>
 
</tr>
</thead>
<tbody>
<tr>
  <td>Otto</td>
  <td>@mdo</td>
  <td>Otto</td>


</tr>

</tbody>
</table>

    
</div>



</div>
    </div>
  )
}

export default Composition