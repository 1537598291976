import React from 'react'

function PostageDefinition({data,setdata}) {
    const gram=(value)=>{
        setdata({...data,weight_grams:value,weight_ounces:value/28.35,weight_pounds:value/453.6,weight_kilograms:value/1000})
        
      }
    return (
        <div className='postage_definetion'>
            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <h5 className='p-2' style={{ color: '#2895d8' }} >Postage definition</h5>
                <div className='row'>
                    <div className='col-3 d-flex justify-content-end align-item-center'>
                        <lable >Default postal service:</lable>
                    </div>
                    <div className='col-9'>
                        <select value={data?.default_postal_service_id} onChange={(e)=>setdata({...data,default_postal_service_id:e.target.value})} type='text' style={{ width: "80%", border: "1px solid lightgray", padding: ".3rem" }}>
                            <option>None</option>
                        </select>

                    </div>
                </div>
                <div style={{ paddingBottom: "1rem" }} className='row'>
                    <div className='col-3 d-flex justify-content-end align-item-center'>
                        <lable >Packaging group:</lable>
                    </div>
                    <div className='col-9'>
                        <select value={data?.packaging_group_id} onChange={(e)=>setdata({...data,packaging_group_id:e.target.value})} type='text' style={{ width: "80%", border: "1px solid lightgray", padding: ".3rem" }}>
                            <option>None</option>
                        </select>

                    </div>
                </div>


            </div>
            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <h5 className='p-2' style={{ color: '#2895d8' }} >Weight <span style={{fontSize:"13px"}}>(by default in 'Kg')</span></h5>
                <div className='row' style={{ paddingBottom: "1rem" }}>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Grams:</lable>
                        <input  onChange={(e)=>gram(e.target.value)} value={data?.weight_grams} type='text' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Oz:</lable>
                        <input value={data?.weight_ounces} disabled={true} type='text' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Pounds:</lable>
                        <input value={data?.weight_pounds} disabled={true} type='text' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Kg:</lable>
                        <input value={data?.weight_kilograms} disabled={true} type='text' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                </div>


            </div>
            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <h5 className='p-2' style={{ color: '#2895d8' }} >Dimension <span style={{fontSize:"13px"}}>((by default in 'Centimetres'))</span></h5>
                <div className='row' style={{ paddingBottom: "1rem" }}>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Width:</lable>
                        <input onChange={(e)=>setdata({...data,width:e.target.value})}  value={data?.width} type='number' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Height:</lable>
                        <input  onChange={(e)=>setdata({...data,height:e.target.value})} value={data?.height} type='number' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Depth:</lable>
                        <input  onChange={(e)=>setdata({...data,depth:e.target.value,volume:data.width*data.height*data.depth})} value={data?.depth} type='number' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                    <div className='col-3 d-flex justify-content-around align-item-center'>
                        <lable >Volume:</lable>
                        <input value={data.width*data.height*data.depth} type='number' style={{ width: "100px", border: "1px solid lightgray", padding: ".3rem" }}/>
                  
                    </div>
                </div>
                


            </div>
        </div>
    )
}

export default PostageDefinition