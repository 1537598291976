import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { FaRegNewspaper } from 'react-icons/fa';
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',

    boxShadow: 24,

  };
function ExtendPO({id,value}) {
    const [data,setdata]=useState({

    })
    const {createExtantPO}=useStateContext()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () =>{
        setOpen(false);
        setTableData([])
    } 
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = tableData.filter((item) => {
        return !select.includes(item);
      });
    
      setTableData(array)
    };
    const auth=JSON.parse(localStorage.getItem("user_token"))?.user
    const addRow=()=>{
        var date=new Date().getFullYear()+"-"+new Date().getMonth()+"-"+new Date().getDate()
        var time=new Date().getHours()+":"+new Date().getMinutes()
        var obj={
            po_id: id,
            date: date,
            name: "",
            value: "",
            email:auth?.email,
            vendor_id: auth?.id,
            time:time
         
        }
  


        setTableData([...tableData,obj])
 
 
    }
    useEffect(()=>{
        setTableData(value)
    },[value])
    const [tableData, setTableData] = useState(value);
    
      const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        setdata(updatedData)
        setTableData(updatedData);
      };
      const sendData=()=>{
        createExtantPO(data)
        handleClose()

      }
  return (
    <>  <button onClick={handleOpen}><FaRegNewspaper /> Properties</button>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between", background: "#44688C", color: "white" }}>
                  <h5>Purchase Order Extended Properties</h5><button onClick={handleClose} style={{ background: "none", border: "none" }}><Close style={{ color: "lightcyan" }} /></button>
                </div>
                <div style={{padding:"1rem",background:"#F9F9F9"}}>
                    
                        <div style={{ display: "flex", justifyContent: "end",}}>
                            <button style={{background:"#E9EAEB",border:"none",padding:".5rem 1rem",margin:".2rem"}} onClick={addRow}>Add New</button>
                            <button onClick={deleteFun}  style={{background:"#E9EAEB",border:"none",padding:".5rem 1rem",margin:".2rem"}}>Delete</button>
                        </div>
                        <div style={{border:"1px solid lightgray",height:"50vh",marginTop:"10px",overflowY:"scroll"}} >

<table class="table table-sm">
  <thead >
    <tr>

      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Date</div>
          
        </div>

      </th>
      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Name</div>
          
        </div>
      </th>
      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Value</div>
          
        </div>
      </th>
      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>User</div>
          
        </div>
      </th>
    



    </tr>
  </thead>
  <tbody>
  {tableData?.map((item, index) => {
            return (
              <tr onClick={(e)=>getselect(item, e)} style={{ background: `${isRowSelected(item) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
              <td><input onChange={(e) => handleInputChange(e, index, 'date')} style={{border:"none",background:"none",outline:"none"}} disabled value={item.date}/></td>
              <td><input   onChange={(e) => handleInputChange(e, index, 'name')} style={{border:"none",background:"none",outline:"none"}} value={item.name}/></td>
              <td><input onChange={(e) => handleInputChange(e, index, 'value')} style={{border:"none",background:"none",outline:"none"}} value={item.value}/></td>
              <td><input onChange={(e) => handleInputChange(e, index, 'email')} style={{border:"none",background:"none",outline:"none"}} disabled value={item.email}/></td>
 
            </tr>
            )
        })}
  </tbody>
</table>
</div>
<div style={{ display: "flex", justifyContent: "end",paddingTop:".5rem"}}>
                            <button style={{background:"#5C7FA5",border:"none",padding:".5rem 1rem",margin:".2rem",color:"white"}} onClick={sendData}>Confirm</button>
                            <button style={{background:"#EAEBEC",border:"none",padding:".5rem 1rem",margin:".2rem"}} onClick={handleClose} >Cancel</button>
                        </div>
                </div>
        </Box>
      </Modal>
    </>
  )
}

export default ExtendPO