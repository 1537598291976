import React, { useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FiSave } from 'react-icons/fi'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,

};
function Productidentifiers({ data, setdata }) {
    const [open, setOpen] = React.useState(false);
    const [row, setrow] = useState({
        product_identifier: "",
        identifier_type: "",
        site: "",
    })
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const addRow = () => {
        setdata({ ...data, pro_identifier: [...data?.pro_identifier, row] })
        handleClose()
        setrow({
            product_identifier: "",
            identifier_type: "",
            site: "",
        })
    }
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = data?.extended_properties?.filter((item, i) => {
        return !select.includes(i);
      });
      setdata({...data,extended_properties:array})
    }
    return (
        <div className='list_main'>

            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <div className='row'>
                    <div className='col-6'>
                        <h5 className='p-2' style={{ color: '#2895d8' }} >Product identifiers:</h5>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <div>
                            <button className='btn_listAction' onClick={handleOpen}><IoIosAdd /> Add New</button>
                            <button className='btn_listAction mx-2' onClick={deleteFun}><RiDeleteBin5Line /> Delete</button>
                        </div>
                    </div>
                </div>
                <div className='list_table' style={{ padding: ".4rem" }}>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Product identifiers</th>
                                <th scope="col">identifier Type</th>
                                <th scope="col">Site</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data?.pro_identifier?.map((item, idx) => {
                                return (
                                    <tr onClick={(e)=>getselect(idx, e)} style={{ background: `${isRowSelected(idx) ? "#93B1C9" : ""}`,color: `${isRowSelected(idx) ? "white" : ""}`}}>
                                        <td>
                                            <input value={item?.product_identifier} style={{ width: "100%", background: "none", border: "none" }}
                                                onChange={(e) => {
                                                    const updatedData = [...data?.pro_identifier];
                                                    updatedData[idx].product_identifier = e.target.value;
                                                    setdata({ ...data, pro_identifier: updatedData })
                                                }}
                                            />
                                        </td>
                                        <td>{item.identifier_type}</td>
                                        <td> <input value={item?.site} style={{ width: "100%", background: "none", border: "none" }}
                                            onChange={(e) => {
                                                const updatedData = [...data?.pro_identifier];
                                                updatedData[idx].site = e.target.value;
                                                setdata({ ...data, pro_identifier: updatedData })
                                            }}
                                        /></td>


                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>


                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 500 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", background: "#44688C", color: "white", padding: "1rem" }}>
                        <h5>Item Chooser</h5>
                        <button style={{ color: "white", background: "none", border: "none" }} onClick={handleClose}>X</button>

                        </div>
                        <div className='modal_body' style={{ height: "30vh" }}>
                            <div className='py-2 px-2'>
                                <div className='row my-3'>
                                    <div className='col-4' style={{ position: "relative" }}>
                                        <label style={{ position: "absolute", right: "0%" }}>Product Identifiers:</label>
                                    </div>
                                    <div className='col-8'>
                                        <input onChange={(e) => setrow({ ...row, product_identifier: e.target.value })} style={{ border: "1px solid lightgray", padding: ".4rem" }} />
                                    </div>
                                </div>
                                <div className='row my-3'>
                                    <div className='col-4' style={{ position: "relative" }}>
                                        <label style={{ position: "absolute", right: "0%" }}>Identifiers type:</label>
                                    </div>
                                    <div className='col-8'>
                                        <select onChange={(e) => setrow({ ...row, identifier_type: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                                            <option hidden></option>
                                            <option value={"ASIN"}>ASIN</option>
                                            <option value={"Customr ID"}>Customr ID</option>
                                            <option value={"EAN"}>EAN</option>
                                            <option value={"EAN"}>EAN</option>
                                            <option value={"GCID"}>GCID</option>
                                            <option value={"Google product identifier"}>Google product identifier</option>
                                            <option value={"GTIN"}>GTIN</option>
                                            <option value={"ISBN"}>ISBN</option>
                                            <option value={"MPN"}>MPN</option>
                                            <option value={"PZN"}>PZN</option>
                                            <option value={"UPC"}>UPC</option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", padding: "1.5rem 1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>
                            <button className='save_btn mx-2' onClick={() => addRow()}><FiSave /> Save</button>
                            <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
                        </div>
                    </Box>
                </Modal>

            </div>
        </div>
    )
}

export default Productidentifiers