import React, { useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FiSave } from 'react-icons/fi';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
};
function ChannelSKU({ data, setdata }) {
    const [row, setrow] = useState({
        status: "",
        sku: "",
        source: "",
        subsource: "",
    })
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true)
        setrow({
            status: "",
            sku: "",
            source: "",
            subsource: "",
        })
    };
    const handleClose = () => setOpen(false);
    const addRow = () => {
        if (row.sku && row.source) {
            setdata({ ...data, channel_sku: [...data?.channel_sku, row] })
            handleClose()
        }
    }
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = data?.channel_sku?.filter((item, i) => {
        return !select.includes(i);
      });
      setdata({...data,channel_sku:array})
    };
    return (
        <div className='list_main'>

            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <div className='row'>
                    <div className='col-6'>
                        <h5 className='p-2' style={{ color: '#2895d8' }} >Channel SKU:</h5>

                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <div>

                            <button className='btn_listAction' onClick={() => handleOpen()} ><IoIosAdd /> Add New</button>
                            <button className='btn_listAction mx-2' onClick={deleteFun} disabled={select?false:true} ><RiDeleteBin5Line /> Delete</button>
                        </div>
                    </div>
                </div>
                <div className='list_table' style={{ padding: ".4rem" }}>
                    <table className="table table-sm">
                        <thead>
                            <tr>

                                <th scope="col" style={{ width: "70px" }}>Ignore</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Source</th>
                                <th scope="col">SubSource</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.channel_sku?.map((item, idx) => {
                                return (
                                    <tr onClick={(e)=>getselect(idx, e)} style={{ background: `${isRowSelected(idx) ? "#93B1C9" : ""}`,color: `${isRowSelected(idx) ? "white" : ""}`}}>
                                        <td>
                                            <input type='checkbox' onChange={(e)=>{
                                                    const updatedData = [...data?.channel_sku];
                                                    updatedData[idx].channel_sku = e.target.checked;
                                                    setdata({...data,channel_sku:updatedData})
                                                }}/>
                                        </td>
                                        <td>{item?.sku}</td>
                                        <td>{item?.source}</td>
                                        <td>{item?.subsource}</td>

                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>


                </div>



            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "space-between", background: "#44688C", color: "white", padding: "1rem" }}>
                        <h5>Add New Category</h5>
                        <button style={{ color: "white", background: "none", border: "none" }} onClick={handleClose}>X</button>
                    </div>
                    <div className='model_childBoxMain'>
                        <div className='row'>
                            <div className='col-5'>
                                <label>SKU:</label>
                            </div>
                            <div className='col-7'>
                                <input value={row?.sku} onChange={(e) => setrow({ ...row, sku: e.target.value })} />
                            </div>
                        </div>
                        <div style={{ marginBottom: "1rem" }} className='row'>
                            <div className='col-5'>
                                <label>Channel:</label>
                            </div>
                            <div className='col-7'>
                                <select value={row?.source} onChange={(e) => setrow({ ...row, source: e.target.value})} >
                                    <option hidden>select channel</option>
                                    <option value={"Amazon-Love My Fashions ES"}>Amazon-Love My Fashions ES</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "1.5rem 1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

                        <button className='save_btn mx-2' onClick={() => addRow()}><FiSave /> Save</button>
                        <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ChannelSKU