import React, { useEffect } from 'react'
import { useStateContext } from '../../Context/ContextProvider'

function General({data,setdata}) {
    const {allCetagore,getCategory}=useStateContext()
useEffect(()=>{
    getCategory()
    // eslint-disable-next-line
},[])
  return (
    <div className='inv_tabInp_main'>
        <h5 className='p-2' style={{color:'#2895d8'}} >General:</h5>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Item number:</lable>
            </div>
            <div className='col-9'>
                <input value={data?.item_no} type='text' style={{width:"80%",border:"1px solid lightgray",background:"#EEEEEE",padding:".3rem"}}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Item title:</lable>
            </div>
            <div className='col-9'>
                <input type='text' value={data?.title} onChange={(e)=>setdata({...data,title:e.target.value})} style={{width:"80%",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Barcode:</lable>
            </div>
            <div className='col-9'>
                <input type='text' value={data?.barcode} onChange={(e)=>setdata({...data,barcode:e.target.value})} style={{width:"80%",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Purchase price:</lable>
            </div>
            <div className='col-9'>
                <input type='text' value={data?.purchase_price} onChange={(e)=>setdata({...data,purchase_price:e.target.value})} style={{width:"100px",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Retail price:</lable>
            </div>
            <div className='col-9'>
                <input type='text' value={data?.retail_price} onChange={(e)=>setdata({...data,retail_price:e.target.value})} style={{width:"100px",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Tax:</lable>
            </div>
            <div className='col-9'>
                <input type='text' value={data?.tax_rate} onChange={(e)=>setdata({...data,tax_rate:e.target.value})} style={{width:"100px",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Category:</lable>
            </div>
            <div className='col-9'>
            <select value={data?.category} onChange={(e)=>setdata({...data,category:e.target.value})} type='text' style={{width:"80%",border:"1px solid lightgray",padding:".3rem"}}>
                {allCetagore?.map((item)=>{
                    <option hidden>None</option>
                    return(
                        <option value={item?.id}>{item.name}</option>

                    )
                })}
                </select>          
                
                  </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Batch type:</lable>
            </div>
            <div className='col-9'>
                <select type='text' style={{width:"200px",border:"1px solid lightgray",padding:".3rem"}}>
                    <option>None</option>
                </select>
            </div>
        </div>
        <div className='row'>
            <div className='col-3 d-flex justify-content-end align-item-center'>
                <lable >Metadata:</lable>
            </div>
            <div className='col-9'>
                <textarea value={data?.meta_data} onChange={(e)=>setdata({...data,meta_data:e.target.value})} type='text' style={{width:"80%",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
        </div>
    </div>
  )
}

export default General