import React, { useEffect } from 'react'
import { useStateContext } from '../../Context/ContextProvider';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
function SnakBar() {
    const {
        snackbarValue,
        setState,
        state
    } = useStateContext()
    const handleClose = () => {
        setState( false );
    };
    useEffect(() => {
        setTimeout(function () {
            handleClose()
        }, 5000);
        // eslint-disable-next-line
    }, [state])
  
  return (
    <>
    {state&&<div style={{background:`${snackbarValue?.color}`}} className='alret'>
        <span> <IoMdCheckmarkCircleOutline  style={{marginRight:"20px"}}/>{snackbarValue?.message}</span>
    </div>}
    </>
    
  )
}

export default SnakBar