import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Add, Close, Delete } from '@mui/icons-material';
import { FiSave } from 'react-icons/fi';
import { VscChromeRestore } from 'react-icons/vsc';
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#F9F9F9',
    boxShadow: 24,
};
const stylee = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F9F9F9',
    boxShadow: 24,
};
function AddView() {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRows1, setSelectedRows1] = useState([]);
    const [arrayfirst, setarrayFirst] = useState([
        "Batch Number",
        "Scan Required",
        "Batch Type",
        "Bin Rack",
        "Category",
        "Created Date",
        "Daily Average Consumption",
        "Default Supplier",
        "Depth",
        "Width",
        "Height",
        "Just In Time",
        "Modified Date",
        "Primary Image",
        "Serial Number Scan Required",
        "Stock Value",
        "Variation Group Name",
        "Weight",
        "Width"
    ])
    const [arraySecond, setarraysecond] = useState(['SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Stock Level', 'In Open Orders', 'Available', 'Minimum Level', 'Tracked'])
    const handleRowClick = (rowIndex, event) => {
        if (event.ctrlKey || event.metaKey) {
            if (selectedRows.includes(rowIndex)) {
                setSelectedRows(selectedRows.filter(row => row !== rowIndex));
            } else {
                setSelectedRows([...selectedRows, rowIndex]);
            }
        } else {
            setSelectedRows([rowIndex]);
        }
    };
    const handleRowClick1 = (rowIndex, event) => {
        if (event.ctrlKey || event.metaKey) {
            if (selectedRows1.includes(rowIndex)) {
                setSelectedRows1(selectedRows1.filter(row => row !== rowIndex));
            } else {
                setSelectedRows1([...selectedRows1, rowIndex]);
            }
        } else {
            setSelectedRows1([rowIndex]);
        }
    };
    const [selectColumn, setselectColumn] = useState([])
    //   const [selectColumn,setselectColumn]=useState([])
    const handleRowClick3 = (rowIndex, event) => {
        if (event.ctrlKey || event.metaKey) {
            if (selectColumn.includes(rowIndex)) {
                setselectColumn(selectColumn.filter(row => row !== rowIndex));
            } else {
                setselectColumn([...selectColumn, rowIndex]);
            }
        } else {
            setselectColumn([rowIndex]);
        }
    };

    const isRowSelected = (rowIndex) => selectedRows.includes(rowIndex);
    const isRowSelected1 = (rowIndex) => selectedRows1.includes(rowIndex);
    const isRowSelected2 = (rowIndex) => selectColumn.includes(rowIndex);
    const header = ["In Open Orders", " Height", " Extended Property ", "GeneralWithSeparatingWords", " Just In Time", " Level", " Main image", " Minimum Level ", "Modified Date", " Purchase", " Depth", " Due", " eBay Id ", "Batch Type", " BinRack ", "Category", " Channel SKU ", "Creation Date ", "Daily Average Consumption", " Default Supplier", " Amazon ASIN", " Available", "Barcode", " Batch Number", " Batch Number Scan Required"]
    const [open, setOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setinpdata({
            view_name: "",
            product_type: "All Products",
            filters: [],
            columns: []
        })
        setdata([])


    }
    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const [data, setdata] = useState()
    const selectData = () => {
        var array = []
        // eslint-disable-next-line
        selectedRows?.map((item, i) => {
            var obj = {
                column: item,
                operator: "equals",
                value: "",

            }
            array.push(obj)
        })
        setdata(array)
        setSelectedRows([])
        handleClose1()
    }
    const [data1, setdata1] = useState(['SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Level', 'In Open Orders', 'Availble', 'Minimum Level', 'Tracked'])
    const handleClose2 = (value, i, varibale) => {
        const updatedData = [...data];
        updatedData[i][varibale] = value;
        setdata(updatedData);
    };
    const handleChage = (value, i, varibale) => {
        const updatedData = [...data1];
        updatedData[i][varibale] = value;
        // eslint-disable-next-line
        updatedData.map((item) => {

            setdata1(updatedData)
        })
    }

    const { createView } = useStateContext()
    const [inpdata, setinpdata] = useState({
        view_name: "",
        product_type: "All Products",
        filters: [],
        columns: []
    })
    const save = () => {
        const obj = {
            view_name: inpdata.view_name,
            product_type: inpdata.product_type,
            filters: data,
            columns: arraySecond
        }
        createView(obj)
        setinpdata({
            view_name: "",
            product_type: "All Products",
            filters: [],
            columns: []
        })
        setdata([])
        setarraysecond(['SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Stock Level', 'In Open Orders', 'Available', 'Minimum Level', 'Tracked'])
        setarrayFirst([
            "Batch Number",
            "Scan Required",
            "Batch Type",
            "Bin Rack",
            "Category",
            "Created Date",
            "Daily Average Consumption",
            "Default Supplier",
            "Depth",
            "Width",
            "Height",
            "Just In Time",
            "Modified Date",
            "Primary Image",
            "Serial Number Scan Required",
            "Stock Value",
            "Variation Group Name",
            "Weight",
            "Width"
        ])
        handleClose()
    }
    const deletecol = () => {
        var array = arraySecond?.filter((item, i) => {
            return !selectColumn.includes(item);
        });

        // setTableData(array)
        var newdata = arrayfirst.concat(selectColumn)
        setarrayFirst(newdata)
        setarraysecond(array)
        setselectColumn([])
    }
    const addcol = () => {
        var array = arrayfirst?.filter((item, i) => {
            return !selectedRows1.includes(item);
        });

        // setTableData(array)
        var newdata = arraySecond.concat(selectedRows1)
        setarrayFirst(array)
        setarraysecond(newdata)
        setSelectedRows1([])
        handleClose3()
    }
    const [filterreamove, setremove] = useState([])

    const isRowSelected4 = (rowIndex) => filterreamove.includes(rowIndex);
    const handleRowClick4 = (rowIndex, event) => {
        if (event.ctrlKey || event.metaKey) {
            if (filterreamove.includes(rowIndex)) {
                setremove(filterreamove.filter(row => row !== rowIndex));
            } else {
                setremove([...filterreamove, rowIndex]);
            }
        } else {
            setremove([rowIndex]);
        }
    };
    const deletefilter = () => {
        var array = data.filter((item) => {
            return !filterreamove.includes(item)
        })
        setdata(array)
    }
    const [expand, setexpand] = useState(false)

    return (
        <>
            <button style={{ border: "1px solid lightgray", color: "gray", marginTop: "2px", marginLeft: "10px" }} onClick={handleOpen} className="addSign_btn">
                <i className="fa fa-plus" />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: expand ? 800 : 700 }} >
                    <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                        <h5>Inventory View Editor</h5>
                        <div>
                            <button onClick={() => setexpand(!expand)}  style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button>
                            <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                        </div>
                    </div>
                    <div style={{ height: "75vh", padding: "1rem 2rem", overflowX: "hidden" }}>

                        <div style={{ borderBottom: "1px solid lightgray", padding: "1rem 0" }} className='row'>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>
                                <label style={{ textAlign: "end" }}>View Name:</label>
                            </div>
                            <div className='col-9'>
                                <input onChange={(e) => setinpdata({ ...inpdata, view_name: e.target.value })} type='text' style={{ padding: ".3rem", border: "1px solid lightgray", width: "100%" }} />
                            </div>
                        </div>
                        <div style={{ borderBottom: "1px solid lightgray", padding: "1rem 0" }} className='row'>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>
                                <label style={{ textAlign: "end" }}>Product type:</label>
                            </div>
                            <div className='col-9'>
                                <select onChange={(e) => setinpdata({ ...inpdata, product_type: e.target.value })} style={{ padding: ".3rem", border: "1px solid lightgray", width: "200px" }}>
                                    {/* <option hidden>Select Product type</option> */}
                                    <option value={"All Products"}>All Products</option>
                                    <option value={"variation_parent"}>Variation Parents</option>
                                    <option value={"Non Variation Parents"}>Non Variation Parents</option>
                                    <option value={"Non part of Variation Group"}>Non part of Variation Group</option>
                                </select>
                            </div>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>
                                <label style={{ textAlign: "end" }}>Only changed products:
                                </label>
                            </div>
                            <div className='col-9'>
                                <input type='checkbox' style={{ padding: ".3rem", border: "1px solid lightgray" }} />
                            </div>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>
                                <label style={{ textAlign: "end" }}>Include archived:
                                </label>
                            </div>
                            <div className='col-9'>
                                <select style={{ padding: ".3rem", border: "1px solid lightgray", width: "200px" }}>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div style={{ color: "#5185D5", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                <h6>Filter</h6>
                                <div>
                                    <button onClick={handleOpen1} style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", margin: "0 .5rem", color: "gray" }}><Add />Add new</button>
                                    <Modal
                                        open={open1}
                                        onClose={handleClose1}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={stylee}>
                                            <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                                <h5>Filter Chooser</h5>
                                                <div>
                                                    {/* <button style={{ border: "none", background: "none" }}>Ex</button> */}
                                                    <button onClick={handleClose1} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                                                </div>
                                            </div>
                                            <div className='view_section'>


                                                {/* Tabs navs */}
                                                <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Product Details</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Extended Properties</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Channels</a>
                                                    </li>
                                                </ul>
                                                {/* Tabs navs */}
                                                {/* Tabs content */}
                                                <div className="tab-content" id="ex1-content">
                                                    <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                                                        <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                            <table className="table table-striped">
                                                                <thead style={{ border: "1px solid lightgray" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {header.map((item, rowIndex) =>
                                                                        <tr
                                                                            key={rowIndex}
                                                                            className={isRowSelected(item) ? 'selcted_tabledata' : ''}
                                                                            onClick={(event) => handleRowClick(item, event)}
                                                                        >
                                                                            <td>{item}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                                                        <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                            <table className="table table-striped">
                                                                <thead style={{ border: "1px solid lightgray" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {header.map((item) => {
                                                                        return (
                                                                            <tr>

                                                                                <td>{item}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                                                        <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                            <table className="table table-striped">
                                                                <thead style={{ border: "1px solid lightgray" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {header.map((item) => {
                                                                        return (
                                                                            <tr>

                                                                                <td>{item}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: "1px solid lightgray", background: "#F0F0F0", display: "flex", justifyContent: "end", padding: "1rem" }}>
                                                <div>
                                                    <button onClick={selectData} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                                                    <button style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                                                </div>
                                            </div>
                                            {/* Tabs content */}
                                        </Box>
                                    </Modal>
                                    <button onClick={deletefilter} style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", color: "gray" }}><Delete />Delete</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", height: "30vh" }} className='table-responsive'>

                            <table className="table table-striped">
                                <thead style={{ border: "1px solid lightgray" }}>
                                    <tr>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Name</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Condition</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Value</th>




                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                className={isRowSelected4(item) ? 'selcted_tabledata' : ''}
                                                onClick={(event) => handleRowClick4(item, event)}
                                            >

                                                <td>{item.column}</td>
                                                <td>
                                                    <select style={{ width: "200px", outline: "none", border: "none", background: "none" }} onChange={(e) => handleClose2(e.target.value, i, "operator")} value={item.operator}>
                                                        <option value={"equals"}>equal</option>
                                                        <option value={"contains"}>Contains</option>
                                                        <option value={"not_contains"}>Not contains</option>
                                                        <option value={"not_equals"}>not equal</option>
                                                    </select>
                                                </td>
                                                <td><input style={{ width: "200px", outline: "none", border: "none", background: "none" }} onChange={(e) => handleClose2(e.target.value, i, "value")} /> </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div style={{ color: "#5185D5", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                <h6>Columns:</h6>
                                <div>
                                    <button onClick={handleOpen3} style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", margin: "0 .5rem", color: "gray" }}><Add />Add new</button>
                                    <Modal
                                        open={open3}
                                        onClose={handleClose3}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={stylee}>
                                            <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                                <h5>Columns Chooser</h5>
                                                <div>
                                                    {/* <button style={{ border: "none", background: "none" }}>Ex</button> */}
                                                    <button onClick={handleClose3} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                                                </div>
                                            </div>
                                            <div className='view_section'>


                                                <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                    <table className="table table-striped">
                                                        <thead style={{ border: "1px solid lightgray" }}>
                                                            <tr>
                                                                <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {arrayfirst.map((item, rowIndex) =>
                                                                <tr
                                                                    key={rowIndex}
                                                                    className={isRowSelected1(item) ? 'selcted_tabledata' : ''}
                                                                    onClick={(event) => handleRowClick1(item, event)}
                                                                >
                                                                    <td>{item}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                            <div style={{ border: "1px solid lightgray", background: "#F0F0F0", display: "flex", justifyContent: "end", padding: "1rem" }}>
                                                <div>
                                                    <button onClick={addcol} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                                                    <button onClick={handleClose3} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                                                </div>
                                            </div>
                                            {/* Tabs content */}
                                        </Box>
                                    </Modal>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", color: "gray" }} onClick={deletecol}><Delete />Delete</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", height: "30vh" }} className='table-responsive'>

                            <table className="table table-striped">
                                <thead style={{ border: "1px solid lightgray" }}>
                                    <tr>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Name</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Sort Direction</th>





                                    </tr>
                                </thead>
                                <tbody>
                                    {arraySecond?.map((item, rowIndex) => {
                                        return (
                                            <tr key={rowIndex}
                                                className={isRowSelected2(item) ? 'selcted_tabledata' : ''}
                                                onClick={(event) => handleRowClick3(item, event)} >

                                                <td>{item}</td>
                                                <td>
                                                    <select style={{ outline: "none", border: "none", background: "none" }} onChange={(e) => handleChage(e.target.value, rowIndex, "sort_direction")} >
                                                        <option value={"none"}>none</option>
                                                        <option value={"equal"}>equal</option>
                                                    </select>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div style={{ color: "#5185D5", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                <h6>Channels:</h6>
                                <div>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", margin: "0 .5rem", color: "gray" }}><Add />Add new</button>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", color: "gray" }}><Delete />Delete</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", height: "30vh" }} className='table-responsive'>

                            <table className="table table-striped">
                                <thead style={{ border: "1px solid lightgray" }}>
                                    <tr>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Source</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">SubSource</th>




                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div style={{ border: "1px solid lightgray", background: "#F0F0F0", display: "flex", justifyContent: "end", padding: "1rem" }}>
                        <div>
                            <button onClick={save} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                            <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default AddView