import React, { useState } from 'react'
import { BiSolidMessageRounded } from 'react-icons/bi'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close, Delete } from '@mui/icons-material';
import { useStateContext } from '../../Context/ContextProvider';
import { useParams } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',

  boxShadow: 24,

};
function NotePO({ value }) {
  const { createnote, delPONote } = useStateContext()
  const id = useParams()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  var date = new Date().getFullYear() + "-" + new Date().getMonth() + "-" + new Date().getDate()
  var time = new Date().getHours() + ":" + new Date().getMinutes()
  const auth = JSON.parse(localStorage.getItem("user_token"))?.user

  const [data, setdata] = useState({

    po_id: id?.id,
    date: date,
    time: time,
    note: ""

  })
  return (
    <><button onClick={handleOpen}><BiSolidMessageRounded /> Notes</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between", background: "#44688C", color: "white" }}>
            <h5>View/Edit Notes</h5><button onClick={handleClose} style={{ background: "none", border: "none" }}><Close style={{ color: "lightcyan" }} /></button>
          </div>
          <div style={{ padding: "1rem", background: "#F9F9F9" }}>
            <h5 style={{ color: "#44688C" }}>Notes for Purchase Order: PO14092343630</h5>
            <textarea onChange={(e) => setdata({ ...data, note: e.target.value })} style={{ width: "100%", background: "white", height: "20vh", border: "1px solid lightgray" }} />
            <div style={{ display: "flex", justifyContent: "end", }}>
              <button onClick={() => createnote(data)} style={{ background: "#44688C", color: "white", border: "none", padding: ".5rem 1rem" }}>Add Note</button>
            </div>
            <div style={{ border: "1px solid lightgray", height: "40vh", marginTop: "10px", overflowY: "scroll" }} >

              <table class="table table-sm">
                <thead >
                  <tr>
                    <th style={{ border: "1px solid lightgray", fontWeight: "lighter", padding: ".5rem" }} scope="col">
                      <div className='row'>
                        <div className='col-9'>Date</div>
                      </div>
                    </th>
                    <th style={{ border: "1px solid lightgray", fontWeight: "lighter", padding: ".5rem" }} scope="col">
                      <div className='row'>
                        <div className='col-9'>Time</div>
                      </div>
                    </th>
                    <th style={{ border: "1px solid lightgray", fontWeight: "lighter", padding: ".5rem" }} scope="col">
                      <div className='row'>
                        <div className='col-9'>User</div>

                      </div>
                    </th>
                    <th style={{ border: "1px solid lightgray", fontWeight: "lighter", padding: ".5rem" }} scope="col">
                      <div className='row'>
                        <div className='col-9'>Note</div>

                      </div>
                    </th>
                    <th style={{ border: "1px solid lightgray", fontWeight: "lighter", padding: ".5rem" }} scope="col">
                      <div className='row'>
                        <div className='col-9'>Delete</div>

                      </div>
                    </th>




                  </tr>
                </thead>
                <tbody>
                  {value?.map((item) => {
                    return (
                      <tr>
                        <td>{item.date}</td>
                        <td>{item.time}</td>
                        <td>{auth?.email}</td>
                        <td>{item.note}</td>
                        <td onClick={() => delPONote(item.id,id?.id)}><Delete /></td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>
            <div style={{ display: "flex", justifyContent: "end", paddingTop: ".5rem" }}>
              <button style={{ background: "#EAEBEC", border: "none", padding: ".5rem 1rem" }} onClick={handleClose}>Close</button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default NotePO