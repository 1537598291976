import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { GoDotFill } from 'react-icons/go';
import { BsTable } from 'react-icons/bs';
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p:1,
    boxShadow: 24,

  };
function Tracked({data,isRowSelected}) {
    const { trackedFun} =useStateContext()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleTraked= (value)=>{
      const obj={
        product_id:data.id,
        tracked:value
      }
      trackedFun(obj)
    }
  return (
    <>
             <GoDotFill onClick={()=>handleTraked(data?.tracked === 0?1:0)} style={{cursor:"pointer", fontSize: "25px", marginLeft: "10px",color: `${data?.tracked === 0 ?"#FF7782": "#AAC99E"  }`}} />
                  <BsTable style={{color: `${isRowSelected(data?.id) ? "white" : "gray"}`}} onClick={handleOpen}/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div style={{border:"1px solid lightgray"}} className='table-responsive'>
        <table class="table table-sm">
    <thead style={{background:"#EAEAEA"}}>
      <tr>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">Name</th>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">Level</th>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">Stock Value</th>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">In open Order</th>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">Available</th>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">Due</th>
        <th style={{border:"1px solid lightgray", padding:".5rem 1rem"}} scope="col">Unit Cost</th>
      </tr>
    </thead> 
    <tbody>
    {data?.warehouse_levels?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
        {/* <th scope="row">1</th> */}
        <td style={{border:"1px solid lightgray"}}>{item?.name}</td>
        <td style={{textAlign:"center",border:"1px solid lightgray"}}>{item?.level==null?0:item?.level}</td>
        <td style={{textAlign:"center",border:"1px solid lightgray"}}>{item?.pro_inventory?.level==null?0:item?.pro_inventory?.level}</td>
        <td style={{textAlign:"center",border:"1px solid lightgray"}}>0</td>
        <td style={{background:`${item?.level==null||item?.level<=0?"#FF7782":"#AAC99E"}`,border:"1px solid lightgray",textAlign:"center",color:"white"}}>{item?.level==null|| item?.level<=0 ?0:item?.level}</td>
        <td style={{textAlign:"center",border:"1px solid lightgray"}}>0</td>
        <td style={{border:"1px solid lightgray"}}>{data?.purchase_price}</td>
      </tr>
  
        )
      })
  }
     
    </tbody>
  </table>
        </div>
        </Box>
      </Modal>
    </>
  )
}

export default Tracked