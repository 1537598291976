import React, { useEffect, useState } from 'react'
import { IoIosAdd, IoIosSearch, IoIosSettings } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'
import TableSection from '../Component/Inventory/TableSection';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FiSave } from 'react-icons/fi';
import InventoryModal from '../Component/Inventory/InventoryModal';
import { useStateContext } from '../Context/ContextProvider';
import AddView from '../Component/Inventory/AddView';
import ExportCSV from '../Component/Inventory/ExportCSV';
import VariationModal from '../Component/Variation/VariationModal';
import EditView from '../Component/Inventory/EditView';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
};
function Inventory() {
    const { getSku, setOpen,archive,sku,filter,wareHouse, opens, getProduct, getByCategory, productDetail, setProductDetail, getallView, allview, getViewId, delView, prodelete, allCetagore, searchBy } = useStateContext()
    const [data, setdata] = useState({
        item_no: "",
        title: "",
        barcode: "",
        purchase_price: "",
        retail_price: "",
        meta_data: "",
        tax: "",
        default_postal_service_id: "",
        packaging_group_id: "",
        weight_grams: "",
        weight_ounces: "",
        weight_pounds: "",
        weight_kilograms: "",
        height: '',
        width: '',
        depth: '',
        volume: '',
        category: "",
        warehouse_id: "",
        image: "",
        images: [],
        pricing_descriptions: [],
        title_descriptions: [],
        product_descriptions: [],
        channel_sku: [],
        pro_supplier: [],
        extendedproperties: [],
        pro_identifier: [],
        pro_inventory: [{
            binrack: "",
            level: "",
            value: "",
        }],
        product_compositions: []
    })
    const [data1, setdata1] = useState({
        sku: "",
        name: "",
        barcode: "",
        purchase_price: "",
        retail_price: "",
        meta_data: "",
        tax: "",
        category: "",
        warehouse_id: "",
        images: [],
        pricing_descriptions: [],
        title_descriptions: [],
        product_descriptions: [],
        extended_properties: [],

    })
    console.log(data1);
    const [selectedIds, setselectedIds] = useState([])
    const handleClose = () => {
        setOpen(false)
        setdata({
            item_no: "",
            title: "",
            barcode: "",
            purchase_price: "",
            retail_price: "",
            meta_data: "test",
            tax: "",
            default_postal_service_id: "",
            packaging_group_id: "",
            weight_grams: "",
            weight_ounces: "",
            weight_pounds: "",
            weight_kilograms: "",
            height: '',
            width: '',
            depth: '',
            volume: '',
            category: "",
            warehouse_id: "",
            image: "",
            images: [],
            pricing_descriptions: [],
            title_descriptions: [],
            product_descriptions: [],
            channel_sku: [],
            pro_supplier: [],
            extendedproperties: [],
            pro_identifier: [],
            pro_inventory: [{
                binrack: "",
                level: "",
                value: "",
            }],
            product_compositions: []
        })
    };
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => {
        setOpen(false)
        setOpen1(false)
        setdata({
            item_no: "",
            title: "",
            barcode: "",
            purchase_price: "",
            retail_price: "",
            meta_data: "test",
            tax: "",
            default_postal_service_id: "",
            packaging_group_id: "",
            weight_grams: "",
            weight_ounces: "",
            weight_pounds: "",
            weight_kilograms: "",
            height: '',
            width: '',
            depth: '',
            volume: '',
            category: "",
            warehouse_id: "",
            image: "",
            images: [],
            pricing_descriptions: [],
            title_descriptions: [],
            product_descriptions: [],
            channel_sku: [],
            pro_supplier: [],
            extendedproperties: [],
            pro_identifier: [],
            pro_inventory: [{
                binrack: "",
                level: "",
                value: "",
            }],
            product_compositions: []
        })
    };;
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [inpValue, setInpValue] = useState("")
    const searchinp = () => {
        if (inpValue) {
            searchBy(inpValue)
        }
        else {
            getProduct()
        }
    }
    const addInven = () => {
        getSku()
    }
    useEffect(() => {
        getProduct()
        setdata({ ...data, item_no: sku?.sku })
        // eslint-disable-next-line
    }, [sku])
    useEffect(() => {
        getallView()
        getProduct()

        // eslint-disable-next-line
    }, [])
    const editModal = (value) => {
        handleOpen1()
        setdata(value)
    }
    const editModal1 = (value) => {
        handleOpen2()
        setdata1(value)
    }
    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.slice(0, maxLength) + '...';
    };
    return (
        <div className='inventory'>
            <div className='filter_section'>
                <select>
                    <option>SKU/Title/Barcode</option>
                </select>
                <input onChange={(e) => setInpValue(e.target.value)} type='text' style={{ width: "250px" }} placeholder='Search by SKU ,Title or Barcode' />
                <select onChange={(e) => getByCategory(e.target.value)}>
                    <option value={0}>All Category</option>
                    {allCetagore?.map((item) => {
                        return (
                            <option value={item.id}>{item.name}</option>
                        )
                    })}
                </select>
                <button onClick={searchinp}> <IoIosSearch />Search</button>
            </div>
            <div className='action_section'>
                <div style={{ display: "flex" }}>
                    <div style={{ paddingLeft: "1rem", background: "#F0F0F0", cursor: "pointer", border: "none", borderTop: "3px solid #44688C", borderLeft: "1px solid #44688C", borderRight: "1px solid #44688C" }}>
                        <span onClick={() => getProduct()}>Default</span>
                        <button style={{ padding: ".5rem .8rem", border: "none" }} className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a href='...' className="dropdown-item">
                                <label for="inv_img">Edit View</label>
                            </a>
                            <a href='...' className="dropdown-item">
                                <label for="inv_img">Delete View</label>
                            </a>
                        </div>
                    </div>
                    {allview?.map((item) => {
                        return (
                            <div style={{ marginLeft: "10px", paddingLeft: "1rem", background: "#F0F0F0", cursor: "pointer" }}>
                                <span onClick={() => getViewId(item.id)}>{truncateText(item.view_name, 9)}</span>
                                <button style={{ padding: ".5rem .8rem", border: "none" }} className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <label className="dropdown-item">
                                        <EditView editValue={item} />
                                    </label>
                                    <label className="dropdown-item">
                                        <label onClick={() => delView(item.id)}>Delete View</label>
                                    </label>
                                </div>
                            </div>

                        )
                    })}
                    <AddView />

                </div>
                <div>
                    <select onChange={(e) => filter(e.target.value)}>
                        <option value={0}>Default</option>

                        {wareHouse?.map((item) => {
                            return (
                                <option value={item.id}>{item.name}</option>

                            )
                        })}
                    </select>
                    <button onClick={addInven}><IoIosAdd />Add New</button>
                    <ExportCSV />


                    <button onClick={() => prodelete(selectedIds)}><RiDeleteBin5Line /> Delete</button>
                   
                    <button  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <IoIosSettings />
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <label className="dropdown-item">
                                        <label onClick={() => archive(selectedIds[0])}>Archive</label>
                                    </label>
                                </div>
                </div>
            </div>
            <TableSection editModal={editModal} editModal1={editModal1} data={data} setdata={setdata} productDetail={productDetail} setProductDetail={setProductDetail} setselectedIds={setselectedIds} selectedIds={selectedIds} />
            <Modal
                keepMounted
                open={opens}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "space-between", background: "#44688C", color: "white", padding: "1rem" }}>
                        <h5>Add New Item</h5>
                        <button style={{ color: "white", background: "none", border: "none" }} onClick={handleClose}>X</button>
                    </div>
                    <div style={{ width: "100%", background: "#F9F9F9", paddingLeft: "1rem" }}>

                        <div className='row' style={{ width: "100%" }}>
                            <div style={{ height: "50px" }} className='col-3 d-flex justify-content-end align-items-center'>
                                <label for="inp_category">Item number:</label>
                            </div>
                            <div style={{ height: "50px" }} className='col-9'>
                                <input value={sku?.sku} disabled id='inp_category' onChange={(e) => setdata({ ...data, name: e.target.value })} style={{ width: "250px", padding: ".4rem", border: "1px solid lightgray", marginTop: "10px" }} type='text' />
                            </div>
                        </div>
                        <div className='row' style={{ width: "100%" }}>
                            <div style={{ height: "50px" }} className='col-3 d-flex justify-content-end align-items-center'>
                                <label for="inp_category">Item title:</label>
                            </div>
                            <div style={{ height: "50px" }} className='col-9'>
                                <input value={data?.title} id='inp_category' onChange={(e) => setdata({ ...data, title: e.target.value })} style={{ width: "250px", padding: ".4rem", border: "1px solid lightgray", marginTop: "10px" }} type='text' />
                            </div>
                        </div>
                        <div className='row' style={{ width: "100%" }}>
                            <div style={{ height: "50px" }} className='col-3 d-flex justify-content-end align-items-center'>
                                <label for="inp_category">Barcode:</label>
                            </div>
                            <div style={{ height: "50px" }} className='col-9'>
                                <input value={data?.barcode} id='inp_category' onChange={(e) => setdata({ ...data, barcode: e.target.value })} style={{ width: "250px", padding: ".4rem", border: "1px solid lightgray", marginTop: "10px" }} type='text' />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "1.5rem 1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

                        <button
                            onClick={() => handleOpen1()}
                            className='save_btn mx-2' disabled={data?.title ? false : true}><FiSave /> Save</button>
                        <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
                    </div>
                </Box>
            </Modal>
            <InventoryModal open={open1} handleClose={handleClose1} data={data} setdata={setdata} />
            <VariationModal open={open2} handleClose={handleClose2} data={data1} setdata={setdata1} />
        </div>
    )
}

export default Inventory