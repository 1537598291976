import React, { useEffect } from 'react'
import { useStateContext } from '../../Context/ContextProvider'

function InventorySection({ data, setdata }) {
    const { wareHouse, WareHouse } = useStateContext()
    useEffect(() => {
        WareHouse()
        // eslint-disable-next-line
    }, [])
    return (
        <div className='list_main'>

            <div style={{ height: "max-content" }} className='inv_tabInp_main'>
                <div className='row'>
                    <div className='col-6'>
                        <p style={{ fontWeight: "bold", marginLeft: "10px" }}>Deleted 2 <span style={{ fontWeight: "lighter" }}>Location</span></p>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <select onChange={(e) => setdata({ ...data, warehouse_id: e.target.value })} style={{ width: "200px", padding: ".3rem", border: "1px solid lightgray", marginRight: "1rem" }}>
                            <option hidden>None</option>
                            {wareHouse?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )

                            })
                            }
                        </select>
                    </div>
                </div>
                <div className='list_table' style={{ padding: ".4rem" }}>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Bin/Rack</th>
                                <th scope="col">Level</th>
                                <th scope="col">Value</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data?.pro_inventory?.map((item,idx) => {
                                return (
                                    <tr>
                                        <td><input value={item?.binrack} style={{ width: "100%", background: "none", border: "none" }}
                                            onChange={(e) => {
                                                const updatedData = [...data?.pro_inventory];
                                                updatedData[idx].binrack = e.target.value;
                                                setdata({ ...data, pro_inventory: updatedData })
                                            }}
                                        /></td>
                                        <td><input value={item?.level} style={{ width: "100%", background: "none", border: "none" }}
                                            onChange={(e) => {
                                                const updatedData = [...data?.pro_inventory];
                                                updatedData[idx].level = e.target.value;
                                                setdata({ ...data, pro_inventory: updatedData })
                                            }}
                                        /></td>
                                        <td><input value={item?.value} style={{ width: "100%", background: "none", border: "none" }}
                                            onChange={(e) => {
                                                const updatedData = [...data?.pro_inventory];
                                                updatedData[idx].value = e.target.value;
                                                setdata({ ...data, pro_inventory: updatedData })
                                            }}
                                        /></td>


                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>


                </div>



            </div>
        </div>
    )
}

export default InventorySection