import React, { useEffect, useState } from 'react'
import { HiQuestionMarkCircle } from 'react-icons/hi2'
import AddScrab from '../Component/ScapItems/AddScrab'
import { useStateContext } from '../Context/ContextProvider'

function StockIn() {
    const {stock,token,base_url}=useStateContext()
    const [data, setdata] = useState("")
    const [po,setpo]=useState([])

    const [alldata,setalldata]=useState({
        product_id:data?.id,
        quantity:0
    })

    useEffect(()=>{
        if(data?.id){

            fetch(`${base_url}get-purchase-orders?product_id=${data.id}`,{
               
                headers:{
                  'Authorization': token,
                }
            })
             .then(res => res.json())
                .then(resp => {

                 if(resp.message!=='No purchase orders found for the given product ID'){

                     setpo(resp.purchase_orders)
                 }
            
                })
        }
        // eslint-disable-next-line
    },[data])
    const send=()=>{
        const obj={
            product_id:data.id,
            quantity:alldata.quantity
        }
        stock(obj)
    }
    return (
        <div className='stock_in' style={{overflowX:"hidden"}}>
            <div className='row'>
                <div className='col-9'>
                    <input placeholder='Scan item SKU or Barcode' />
                    <AddScrab  setdata={setdata} data={data} />
                </div>
                <div className='col-3'>
                    <select disabled={true}>
                        <option>

                            Deleted 2
                        </option>
                    </select>
                </div>
            </div>
           {data? <div className='product_box_stock'>
                <div className='row'>
                    <div className='col-6'>
                        <div style={{ border: "1px solid lightgray", margin: "1rem", padding: "1rem" }} className='row'>

                            <div className='col-3'>
                                <div style={{ border: "1px solid lightgray", width: "150px", height: "150px", padding: ".5rem" }}>
                                    <img src='https://krot.info/uploads/posts/2023-03/1680052438_krot-info-p-nur-fettakhoglu-instagram-53.png' alt='...' style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                            <div className='col-9'>
                                <h6 style={{ textAlign: 'end', color: "#0088CC" }}>{data?.title}</h6>
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <p>Stock level:</p>
                                    <p>{data?.pro_inventory?.level}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <p>In open orders:</p>
                                    <p>0</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <p>Awaiting from supplier:</p>
                                    <p>0</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", margin: "1rem", padding: "1rem" }}>
                            <div className='row' >

                                <div className='col-6'>
                                    <label className='mx-4 my-2'>Bin/Rack:</label>
                                    <input disabled  value={data?.pro_inventory?.binrack} style={{ border: "1px solid lightgray", width: "150px" }} />
                                </div>
                                <div className='col-6'>
                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>
                                        <label>Purchase price:</label>
                                        <input disabled value={data?.purchase_price} style={{ border: "1px solid lightgray", width: "80px" }} />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>
                                        <label>Stock level:</label>
                                        <input type='number' value={alldata.quantity} onChange={(e)=>setalldata({...alldata,quantity:e.target.value})} style={{ border: "1px solid lightgray", width: "80px" }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "end", marginTop: ".5rem" }}>
                                <label>Mark items as delivered in selected purchase orders: </label>
                                <input type='checkbox' />
                                <HiQuestionMarkCircle style={{ color: "#0088CC", margin: "3px" }} />

                            </div>
                            <div style={{ display: "flex", justifyContent: "end", marginTop: ".5rem" }}>
                                <label>Process selected orders as stock booked in:  </label>
                                <input type='checkbox' />
                                <HiQuestionMarkCircle style={{ color: "#0088CC", margin: "3px" }} />

                            </div>
                            <div style={{ display: "flex", justifyContent: "end", marginTop: ".5rem" }}>
                                <label>Print labels when booking in: </label>
                                <input type='checkbox' />
                                <HiQuestionMarkCircle style={{ color: "#0088CC", margin: "3px" }} />

                            </div>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <button onClick={send} style={{ border: "none", background: "#869DB7", marginTop: "1rem", color: "white", padding: ".5rem 1rem" }}>Book in</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <h4 style={{marginLeft:"1.8rem",color:"#0088CC"}}>Purchase Orders:</h4>
                        <div className='table_box table_extra'>
                            <div className='table-responsive'>

                                <table style={{ width: "100%" }} class="table table-sm">
                                    <thead style={{background:"#F9F9F9"}}>
                                        <tr>

                                            <th scope="col">
                                                <div className='row'>
                                                    <div className='col-9'>Date</div>
                                                   
                                                </div>

                                            </th>
                                            <th scope="col">
                                                <div className='row'>
                                                    <div style={{ whiteSpace: "nowrap" }} className='col-8'>Expected Date</div>
                                                   
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className='row'>
                                                    <div className='col-9'>PO</div>
                                                   
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className='row'>
                                                    <div className='col-9'>Status</div>
                                                   
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className='row'>
                                                    <div className='col-9'>Supplier</div>
                                                   
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className='row'>
                                                    <div className='col-9'>Location</div>
                                                   
                                                </div>
                                            </th>
                                          




                                        </tr>
                                    </thead>
                                    <tbody>
                                        {po?.map((item,i)=>{
        return(
            <tr className={`${i%2===0?"bg":""}`}  style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>
            <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.date}</td>
            <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.expected}</td>
            <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.reference}</td>
            <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.status}</td>
            <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.supplier?.supplier_name}</td>
            <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.warehouse?.name}</td>
            {/* <td style={{background:data?.id===item.id?"#44688C":"",color:data?.id===item.id?"white":""}}>{item.total_amount}</td> */}
  
           
          </tr>
        )
    })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>:""}
        </div>
    )
}

export default StockIn