import React from 'react'
import { BiBook} from 'react-icons/bi'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close} from '@mui/icons-material';
// import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',

    boxShadow: 24,

  };
function AutidLog({value}) {   
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  return (
    <> <button onClick={handleOpen}><BiBook />Autid Log</button>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between", background: "#44688C", color: "white" }}>
                  <h5>Audit Log</h5><button onClick={handleClose} style={{ background: "none", border: "none" }}><Close style={{ color: "lightcyan" }} /></button>
                </div>
                <div style={{padding:"1rem",background:"#F9F9F9"}}>
                     
                        <div style={{border:"1px solid lightgray",height:"40vh",marginTop:"10px",overflowY:"scroll"}} >

<table class="table table-sm">
  <thead >
    <tr>

      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Date</div>
          
        </div>

      </th>
      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Time</div>
          
        </div>
      </th>
      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Note</div>
          
        </div>
      </th>
      <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>User</div>
          
        </div>
      </th>
    
      {/* <th style={{border:"1px solid lightgray",fontWeight:"lighter",padding:".5rem"}} scope="col">
        <div className='row'>
          <div className='col-9'>Delete</div>
          
        </div>
      </th> */}
    



    </tr>
  </thead>
  <tbody>
  {value?.map((item,i)=>{
    return(
      <tr style={{background:`${i%2===0?"white":""}`}}>
        <td style={{border:"1px solid lightgray"}}>{item.date?.split(" ")[0]}</td>
        <td style={{border:"1px solid lightgray"}}>{item.date?.split(" ")[1]}</td>
        <td style={{border:"1px solid lightgray"}}>{item.note}</td>
        <td style={{border:"1px solid lightgray"}}>{item.username}</td>
        {/* <td style={{border:"1px solid lightgray"}}><Delete/></td> */}
      </tr>
    )
  })}
  </tbody>
</table>
</div>
<div style={{ display: "flex", justifyContent: "end",paddingTop:".5rem"}}>
                            <button onClick={handleClose} style={{background:"#EAEBEC",border:"none",padding:".5rem 1rem"}}>Close</button>
                        </div>
                </div>
        </Box>
      </Modal>
    </>
  )
}

export default AutidLog