
import {Delete} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useStateContext } from '../Context/ContextProvider';
import { PiDownload } from 'react-icons/pi'
import { useParams } from 'react-router-dom';
function ViewTransfer() {
    const {getTransferDetail,  WareHouse, wareHouse, allTransfer } = useStateContext()
    console.log(allTransfer);
    const [detail, setdetail] = useState()
    useEffect(() => {
        setdetail({
            transfer_id: allTransfer?.transfer?.transfer_id,
            to_warehouse_id: allTransfer?.transfer?.to_warehouse_id,
            from_warehouse_id: allTransfer?.transfer?.from_warehouse_id,
            products: allTransfer?.products,
            statu: "in transit"
        })
        // eslint-disable-next-line
    }, [allTransfer])

    useEffect(() => {

        WareHouse()
        // eslint-disable-next-line
    }, [])
    const parms = useParams()
    useEffect(() => {
        getTransferDetail(parms.id)
        // eslint-disable-next-line
    }, [parms.id])

    return (
        <>
            <div className='new_stok py-4'>
                {/* <Topbar */}
                <h4 style={{ fontWeight: "bold" }}>Stock Request<span style={{ background: "#E8E6E6", color: "gray", fontSize: "13px", padding: "0.3rem 1rem ", borderRadius: "50px" }}>Draft</span><span style={{ background: "#FFD7D3", color: "#C66362", fontSize: "13px", padding: "0.3rem 1rem ", borderRadius: "50px", marginLeft: "10px" }}>Beta</span></h4>
                {/* Button trigger modal */}
                {/* <button onClick={deltranfer} className="btnn" >
                    <RiDeleteBin5Fill /> Delete transfer
                </button> */}

                {/* Modal */}
                {/* <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div style={{ border: "none" }} className="modal-header ">
                                <h5 className="modal-title" id="exampleModalLabel">Add items</h5>
                                <button style={{ border: "none", background: "none", color: "gray" }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><Close /></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='inp'>
                                    <label><Search /><input /></label>
                                </div>
                                <table className='table'>
                                    <thead>
                                        <tr style={{ background: "#F3F3F3" }}>
                                            <th scope="col"></th>
                                            <th scope="col" style={{ width: "500px" }}>Items detail</th>
                                            <th scope="col"  >Availble
                                                <p style={{ margin: "0", fontWeight: "lighter", fontSize: "13px" }}>default</p>
                                            </th>
                                            <th scope="col">Availble
                                                <p style={{ margin: "0", fontWeight: "lighter", fontSize: "13px" }}>uk warehouse</p>
                                            </th>
                                            <th scope="col">Due</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            transferData?.map((item) => {
                                                return (
                                                    <TableRow onSelect={handleRowSelect} rowId={item.id} selectedRows={selectedRows} item={item} />
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="py-1 px-2" style={{ border: "1px solid lightgray", color: "gray", fontWeight: "bold" }} data-dismiss="modal">Close</button>
                                <button type="button" style={{ border: "1px solid lightgray", background: "#44688C", color: "lightgray", fontWeight: "bold" }} onClick={() => setProductSelect(true)} className="py-1 px-2"> <LiaSave className='mx-2' /> Save</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='select_box my-3' >

                    <div className='row py-4 '>
                        <div className='col-3'>
                            <label>Request Reference</label>
                            <p>{detail?.transfer_id}</p>
                        </div>
                        <div className='col-3'>
                            <label>Ship From<span style={{ color: "red" }}>*</span></label>
                            <select  disabled value={detail?.from_warehouse_id}  placeholder='Default' style={{ padding: ".7rem 1rem", width: "100%",border:"none" }} >

                                <option hidden >Select WareHouse</option>
                                {wareHouse?.map((item) => {

                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )

                                })

                                }

                            </select>
                        </div>
                        <div className='col-6'>
                            <label>Destination Warehouse<span style={{ color: "red" }}>*</span></label>
                            <select disabled value={detail?.to_warehouse_id}  placeholder='Default' style={{ padding: ".7rem 1rem", width: "75%",border:"none"  }} >
                                <option hidden >Select WareHouse</option>
                                {wareHouse?.map((item) => {
                                    return (

                                        <option value={item.id}>{item.name}</option>
                                    )

                                })

                                }

                            </select>

                        </div>
                    </div>
                </div>
                <div className='box_product' style={{ overflowY: "scroll" }}>
                    <div className='row'>
                        <div className='col-9'>
                            <h5>Add and pack products</h5>
                        </div>
                        <div className='col-3'>

                            <button type="button" className="bt2">
                                <PiDownload /> Import product
                            </button>
                            {/* <button type="button" className="btnnn" data-toggle="modal" data-target="#exampleModal">
                                <BiPlus /> Add Products
                            </button> */}


                        </div>

                        <table className='table my-5'>
                            <thead>
                                <tr style={{ background: "#F3F3F3" }}>
                                    <th scope="col"></th>
                                    <th scope="col" style={{ width: "500px" }}>Items detail</th>
                                    <th scope="col"  >Availble
                                        <p style={{ margin: "0", fontWeight: "lighter", fontSize: "13px" }}>{allTransfer?.from_warehouse?.name}</p>
                                    </th>
                                    <th scope="col">Availble
                                        <p style={{ margin: "0", fontWeight: "lighter", fontSize: "13px" }}>{allTransfer?.to_warehouse?.name}</p>
                                    </th>
                                    <th scope="col">Due</th>
                                    <th scope="col">Qty Received</th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>

                            <tbody>
                                {detail?.products?.map((item, i) => {
                                    return (
                                        <tr >
                                            <td><input type="checkbox" /></td>
                                            <td><div style={{ display: "flex", flexWrap: "wrap", width: "500px" }} >
                                                <div>
                                                    {item?.product?.image ? <img src={"https://wholesaleshopping.tv/linnworks/admin/public/" + item?.product?.image} alt='...' /> : <img src='https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp' alt='...' />}

                                                </div>
                                                <div>

                                                    <h6>{item.title}</h6>
                                                    <span style={{ fontWeight: "bold", color: "#2895d8" }}>{item?.product?.item_no}</span>
                                                </div>
                                            </div></td>
                                            <td>{item.level_from_warehouse ? item.level_from_warehouse : "0"}</td>
                                            <td>{item.level_to_warehouse ? item.level_to_warehouse : "0"}</td>
                                            <td>0</td>
                                            <td>
                                                <input onChange={(e) => {
                                                    const newData = [...detail.products];
                                                    newData[i].quantity_in_transfer = e.target.value;
                                                    setdetail({
                                                        transfer_id: allTransfer?.transfer?.transfer_id,
                                                        to_warehouse_id: allTransfer?.transfer?.to_warehouse_id,
                                                        from_warehouse_id: allTransfer?.transfer?.from_warehouse_id,
                                                        products: newData
                                                    });

                                                }} style={{ cursor: `${item.level_from_warehouse === "0" || item.level_from_warehouse === null ? "not-allowed" : ""}`, outline: "none", border: "none", fontSize: "12px" }} disabled={item.level_from_warehouse === "0" || item.level_from_warehouse === null ? true : false} value={item.quantity_in_transfer} />
                                            </td>
                                            <td><Delete /></td>

                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-8'></div>
                    <div className='col-4'>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>

                    {/* <button style={{ border: "none", background: "#44688C", color: "white", padding: "0.5rem 1rem", borderRadius: "5px", margin: "1rem 0 0 0" }} onClick={() => transferStatus(detail,"recevied")}>Mark Received</button> */}
                </div>
            </div>
        </>



    )
}


export default ViewTransfer