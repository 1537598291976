import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FiSave } from 'react-icons/fi';
import { RiQuestionFill } from 'react-icons/ri';
import General from './General';
import ImageSection from './ImageSection';
import ListingDiscription from './ListingDiscription';
import Extendedproperties from './Extendedproperties';
import { useStateContext } from '../../Context/ContextProvider';
function VariationModal({ open, handleClose ,data,setdata}) {
  const {updateVariation}=useStateContext()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between", background: "#44688C", color: "white", padding: "1rem" }}>
          <h5>Product Details - {data?.sku} | {data?.name}</h5>
          <button style={{ color: "white", background: "none", border: "none" }} onClick={handleClose}>X</button>
        </div>
        <div className='inventory_modal'>
          <div>
            <ul className="nav nav-tabs" id="myTabs">
              <li className="nav-item">
                <a className="nav-link active" id="tab1-tab" data-bs-toggle="tab" href="#tab20">
                General <RiQuestionFill color='lightgray' style={{marginLeft:"5px",fontSize:"18px"}} />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="tab2-tab" data-bs-toggle="tab" href="#tab21">
                Image <RiQuestionFill color='lightgray' style={{marginLeft:"5px",fontSize:"18px"}} />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="tab3-tab" data-bs-toggle="tab" href="#tab23">
                Listing descriptions <RiQuestionFill color='lightgray' style={{marginLeft:"5px",fontSize:"18px"}} />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="tab4-tab" data-bs-toggle="tab" href="#tab24">
                Extended properties <RiQuestionFill color='lightgray' style={{marginLeft:"5px",fontSize:"18px"}} />
                </a>
              </li>
             
              {/* Add more tabs as needed */}
            </ul>

            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab20">
               <General  data={data} setdata={setdata}/>
              </div>
              <div className="tab-pane fade" id="tab21">
              <ImageSection data={data} setdata={setdata}/>
              </div>
              <div className="tab-pane fade" id="tab23">
                <ListingDiscription data={data} setdata={setdata}/>
              </div>
              <div className="tab-pane fade" id="tab24">
                <Extendedproperties data={data} setdata={setdata}/>
              </div>

              {/* Add more tab content as needed */}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem", border: "1px solid lightgray", background: "#F0F0F0" }}>

          <button

            onClick={()=>{
                if(data?.id){
                  updateVariation(data)
                    handleClose()
                }
            }}
            className='save_btn mx-2' ><FiSave /> Save</button>
          <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
        </div>
      </Box>
    </Modal>
  )
}

export default VariationModal