import React, { useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'

function Extendedproperties({data,setdata}) {
        const addrow=()=>{
                var obj={
        name:"",
        value:"",
        type:"",
     
    }
    setdata({...data,extendedproperties:[...data?.extendedproperties,obj]})
        }
        const [multipulSelct,setmultipulSelct]=useState([])
        const [select,setselect]=useState([])
        const getselect = (id, event) => {
          if (event.ctrlKey || event.metaKey) {
            setselect([...select, id]);
            setmultipulSelct([...multipulSelct, id])
          } else {
            setselect([id]);
            setmultipulSelct([id])
          }
        }
        const isRowSelected = (id) => select.includes(id);
        const deleteFun = () => {
          var array = data?.extended_properties?.filter((item, i) => {
            return !select.includes(i);
          });
          setdata({...data,extended_properties:array})
        }
  return (
    
    <div className='list_main'>

    <div style={{ height: "max-content" }} className='inv_tabInp_main'>
    <div className='row'>
        <div className='col-6'>
<h5 className='p-2' style={{ color: '#2895d8' }} >Extended properties:</h5>

        </div>
        <div  className='col-6 d-flex justify-content-end'>
            <div>

            <button className='btn_listAction' onClick={addrow}><IoIosAdd/> Add New</button>
            <button className='btn_listAction mx-2' onClick={deleteFun}><RiDeleteBin5Line/> Delete</button>
            </div>
        </div>
    </div>
<div className='list_table'  style={{ padding: ".4rem" }}>
<table className="table table-sm">
<thead>
<tr>
  <th scope="col">Name</th>
  <th scope="col">Value</th>
  <th scope="col">Type</th>
 
</tr>
</thead>
<tbody>
    {data?.extendedproperties?.map((item,idx)=>{
return(
    <tr onClick={(e)=>getselect(idx, e)} style={{ background: `${isRowSelected(idx) ? "#93B1C9" : ""}`,color: `${isRowSelected(idx) ? "white" : ""}`}}>
  <td>
  <input value={item?.name} style={{width:"100%",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.extendedproperties];
                                                updatedData[idx].name = e.target.value;
                                                setdata({...data,extendedproperties:updatedData})
                                            }}
                                            />
  </td>
  <td><input value={item?.value} style={{width:"100%",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.extendedproperties];
                                                updatedData[idx].value = e.target.value;
                                                setdata({...data,extendedproperties:updatedData})
                                            }}
                                            /></td>
  <td><input value={item?.type} style={{width:"100%",background:"none",border:"none"}} 
                                            onChange={(e)=>{
                                                const updatedData = [...data?.extendedproperties];
                                                updatedData[idx].type  = e.target.value;
                                                setdata({...data,extendedproperties:updatedData})
                                            }}
                                            /></td>


</tr>
)
    })}


</tbody>
</table>

    
</div>



</div>
    </div>
  )
}

export default Extendedproperties
