import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal'
import { FiEdit, FiFilter, FiSave } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 760,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // borderRadius:"10px",
    // p: 4,
  };    
  
function Suppliers() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setdata({  supplier_name:"",
        contact_name:"",
        address:"",
        alternative_add:"",
        city_town:"",
        region:"",
        country:"",
        contact_telephone:"",
        secondary_telephone:"",
        email:"",
        currency_id:"2",
        fax_number:"",
        post_code:"",
        webpage:"",})
        setValidation(false)
        setOpen(false)
    };
    const [multipulSelct,setmultipulSelct]=useState([])
    const [data,setdata]=useState({
        supplier_name:"",
        contact_name:"",
        address:"",
        alternative_add:"",
        city_town:"",
        region:"",
        country:"",
        contact_telephone:"",
        secondary_telephone:"",
        email:"",
        currency_id:"2",
        fax_number:"",
        post_code:"",
        webpage:"",
    })
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const { createSuplier, getsuplier, supplier, editsupplier,currency,currencyList, delsupplier } = useStateContext()
    useEffect(() => {
      getsuplier()
      currency()
      // eslint-disable-next-line
    }, [])
    const editOpen=(item)=>{
        handleOpen()
        setdata(item)
    }
    const [validation,setValidation]=useState(false)
    return (
        <div className='categories'>
            <div className='button_section'>
                <button onClick={handleOpen} className='btn_all'><IoIosAdd/> Add New</button>
                <button disabled={multipulSelct?false:true} style={{cursor:`${multipulSelct?"pointer":'not-allowed'}`}} onClick={()=>delsupplier(multipulSelct)} className='btn_all'><RiDeleteBin5Line/>Delete</button>
            </div>
            <div className='table_all'>

                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th style={{width:"20px"}} scope="col"></th>
                            <th scope="col">Name</th>
                            <th style={{width:"20px"}} scope="col"><FiFilter/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplier?.map((item,i)=>{
                            return(
                                <tr onClick={(e)=>getselect(item.id, e)} style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}></td>
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>{item?.supplier_name}</td>
                
                                <td style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                                    <button style={{background:"none",border:"none" ,color:"gray"}} onClick={()=>editOpen(item)}>
                                    <FiEdit />
                                    </button>
                                </td>
                            </tr>
                            )
                        })}
                     
                     

                    </tbody>
                </table>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div style={{display:"flex",justifyContent:"space-between",background:"#44688C",color:"white",padding:"1rem"}}>
                <h5>Add New Supplier</h5>
                <button style={{color:"white",background:"none",border:"none"}} onClick={handleClose}>X</button>
            </div>
            <div style={{background:"#F9F9F9",padding:"1rem",height:"70vh",overflowY:"scroll"}} className='location'>
            <div className='row'>
                        {validation&&<p className='text-danger'>Name field must be required</p>}
                        <div style={{border:"1px solid lightgray",width:"90%",marginTop:"10px",margin:"auto",padding:".5rem",background:"white"}}>
                            <h6 style={{color:"#1F7BAD",fontSize:"16px"}}>Name:</h6>
                            <div className='row'>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Supplier Name:</label>
                    </div>
                    <div className='col-9'>

                <input value={data?.supplier_name} id='inp_category' onChange={(e)=>setdata({...data,supplier_name:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Contact Name:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.contact_name} id='inp_category' onChange={(e)=>setdata({...data,contact_name:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                            </div>
                        </div>
                        <div style={{border:"1px solid lightgray",width:"90%",marginTop:"10px",margin:"auto",padding:".5rem",background:"white"}}>
                            <h6 style={{color:"#1F7BAD",fontSize:"16px"}}>Contact Data:</h6>
                            <div className='row'>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Address:</label>
                    </div>
                    <div className='col-9'>

                <input value={data?.address} id='inp_category' onChange={(e)=>setdata({...data,address:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Alternative address:</label>
                    </div>
                    <div className='col-9'>

                <input value={data?.alternative_add} id='inp_category' onChange={(e)=>setdata({...data,alternative_add:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>City / Town:</label>
                    </div>
                    <div className='col-9'>

                <input value={data?.city_town} id='inp_category' onChange={(e)=>setdata({...data,city_town:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Region:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.region} id='inp_category' onChange={(e)=>setdata({...data,region:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Country :</label>
                    </div>
                    <div className='col-9'>

                <input value={data?.country} id='inp_category' onChange={(e)=>setdata({...data,country:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Post Code:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.post_code} id='inp_category' onChange={(e)=>setdata({...data,post_code:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Contact telephone:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.contact_telephone} id='inp_category' onChange={(e)=>setdata({...data,contact_telephone:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Secondary Telephone:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.secondary_telephone} id='inp_category' onChange={(e)=>setdata({...data,secondary_telephone:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Fax number :</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.fax_number} id='inp_category' onChange={(e)=>setdata({...data,fax_number:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>E-mail:</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.email} id='inp_category' onChange={(e)=>setdata({...data,email:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Webpage :</label>

                    </div>
                    <div className='col-9'>

                <input value={data?.webpage} id='inp_category' onChange={(e)=>setdata({...data,webpage:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}} type='text' />
                    </div>
                            </div>
                        </div>
                        <div style={{border:"1px solid lightgray",width:"90%",marginTop:"10px",margin:"auto",padding:".5rem",background:"white"}}>
                            <h6 style={{color:"#1F7BAD",fontSize:"16px"}}>Other:</h6>
                            <div className='row'>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                    <label for="inp_category" style={{marginTop:"10px"}}>Currency:</label>
                    </div>
                    <div className='col-9'>

                <select value={data?.currency_id} id='inp_category' onChange={(e)=>setdata({...data,currency_id:e.target.value})} style={{width:"350px",padding:".5rem",border:"1px solid lightgray",marginTop:"10px"}}>
                {currencyList?.map((item)=>{
                  return(
                    <option value={item.id}>{item.name}</option>
                 
              )
              })}
                </select>
                    </div>
                
                            </div>
                        </div>
                    
                 

                </div>

              
            </div>
            <div style={{display:"flex",justifyContent:"flex-end",padding:"1.5rem 1rem",border:"1px solid lightgray",background:"#F0F0F0"}}>
                
                <button  onClick={()=>{
                    if(data?.id){
                        editsupplier(data)
                        handleClose()
                    }
                    else{
                        if(data.supplier_name){
                            createSuplier(data)
                            handleClose()
                        }
                        else{
                            setValidation(true)
                            setTimeout(() => {
                                setValidation(false)
                            }, 3000);
                        }
                    }
                }} className='save_btn mx-2'><FiSave /> Save</button>
                <button className='cancel_btn mx-2' onClick={handleClose}>Cancel</button>
            </div>
        </Box>
      </Modal>
        </div>
    )
}

export default Suppliers