import React from 'react'

function ItemStats({data}) {
  return (
  
    <div className='tab_sectoion'>
             
    {/* Tabs navs */}
    <ul className="nav nav-tabs mb-3" id="ex-with-icons" role="tablist">
      <li className="nav-item" role="presentation">
        <a className="nav-link active" id="ex-with-icons-tab-1" data-mdb-toggle="tab" href="#ex-with-icons-tabs-1" role="tab" aria-controls="ex-with-icons-tabs-1" aria-selected="true">Stock</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-2" data-mdb-toggle="tab" href="#ex-with-icons-tabs-2" role="tab" aria-controls="ex-with-icons-tabs-2" aria-selected="false">Supply</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-3" data-mdb-toggle="tab" href="#ex-with-icons-tabs-3" role="tab" aria-controls="ex-with-icons-tabs-3" aria-selected="false">PO</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-4" data-mdb-toggle="tab" href="#ex-with-icons-tabs-4" role="tab" aria-controls="ex-with-icons-tabs-4" aria-selected="false">Also Sold</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-5" data-mdb-toggle="tab" href="#ex-with-icons-tabs-5" role="tab" aria-controls="ex-with-icons-tabs-5" aria-selected="false">Returns</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-6" data-mdb-toggle="tab" href="#ex-with-icons-tabs-6" role="tab" aria-controls="ex-with-icons-tabs-6" aria-selected="false">Scrap</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-7" data-mdb-toggle="tab" href="#ex-with-icons-tabs-7" role="tab" aria-controls="ex-with-icons-tabs-7" aria-selected="false">Sold Stats</a>
      </li>
    </ul>
    {/* Tabs navs */}
    {/* Tabs content */}
    <div className="tab-content" id="ex-with-icons-content">
      <div className="tab-pane fade show active" id="ex-with-icons-tabs-1" role="tabpanel" aria-labelledby="ex-with-icons-tab-1">
        <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Level</th>
        <th scope="col">In open Order</th>
        <th scope="col">Availble</th>
        <th scope="col">Due</th>
        <th scope="col">Unit Cost</th>
      </tr>
    </thead>
    <tbody>
    {data?.warehouse_levels?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
        {/* <th scope="row">1</th> */}
        <td>{item?.name}</td>
        <td style={{textAlign:"center"}}>{item?.level==null?0:item?.level}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td style={{background:`${item?.level===null||item?.level ===0?"#FF7782":"#AAC99E"}`,textAlign:"center",color:"white"}}>{item?.level===null?0:item?.level}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td>{data?.purchase_price}</td>
      </tr>
  
        )
      })
  }
     
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-2" role="tabpanel" aria-labelledby="ex-with-icons-tab-2">
      <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Default</th>
        <th scope="col">Supplier</th>
        <th scope="col">Code</th>
        <th scope="col">Pur.price</th>
        <th scope="col">Min.price</th>
        <th scope="col">Max.price</th>
        <th scope="col">Avg.price</th>
        <th scope="col">Avg lead Time</th>
      </tr>
    </thead>
    <tbody>
      {data?.pro_supplier?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
       
        <td></td>
        <td>{item?.name}</td>
        <td style={{textAlign:"center"}}>{item?.supplier_code}</td>
        <td style={{textAlign:"center"}}>{item?.purchase_price}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td style={{textAlign:"center"}}>0</td>
        <td style={{textAlign:"center"}}>0</td>
        <td style={{textAlign:"center"}}>{item?.lead_time}</td>
      </tr>
  
        )
      })
  }
      
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-3" role="tabpanel" aria-labelledby="ex-with-icons-tab-3">
      <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Supplier</th>
        <th scope="col">Location</th>
        <th scope="col">PO Date</th>
        <th scope="col">Del.Date</th>
        <th scope="col">Quantity</th>
        <th scope="col">Delivered</th>
        <th scope="col">Unit Cost</th>
      </tr>
    </thead>
    <tbody>
    {data?.purchase_orders?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
       
        <td></td>
        <td>{item?.supplier?.supplier_name}</td>
        <td style={{textAlign:"center"}}>{item?.warehouse?.name}</td>
        <td style={{textAlign:"center"}}>{item?.date}</td>
        <td style={{textAlign:"center"}}>{item?.expected}</td>
        <td style={{textAlign:"center"}}>{item?.pivot?.qty}</td>
        <td style={{textAlign:"center"}}>{item?.pivot?.qty}</td>
        <td style={{textAlign:"center"}}>{item?.pivot?.unit_cost}</td>
      </tr>
  
        )
      })
    }
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-4" role="tabpanel" aria-labelledby="ex-with-icons-tab-4">
      <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">SKU</th>
        <th scope="col">Item Title</th>
        <th scope="col">Quantity</th>
  
      </tr>
    </thead>
    <tbody>
    {/* {data?.purchase_orders?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
       
        <td></td>
        <td>{item.supplier}</td>
        <td style={{textAlign:"center"}}>{item.location}</td>
        <td style={{textAlign:"center"}}>{item.date}</td>
        <td style={{textAlign:"center"}}>{item.expected}</td>
        <td style={{textAlign:"center"}}>{item?.pivot?.qty}</td>
        <td style={{textAlign:"center"}}>{item.lead_time}</td>
      </tr>
  
        )
      })
    } */}
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-5" role="tabpanel" aria-labelledby="ex-with-icons-tab-5">
      <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
      <th scope="col">Month</th>
        <th scope="col">Quantity</th>
        <th scope="col">Reason</th>
      </tr>
    </thead>
    <tbody>
    {/* {data?.purchase_orders?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
       
        <td></td>
        <td>{item.supplier}</td>
        <td style={{textAlign:"center"}}>{item.location}</td>
        <td style={{textAlign:"center"}}>{item.date}</td>
        <td style={{textAlign:"center"}}>{item.expected}</td>
        <td style={{textAlign:"center"}}>{item?.pivot?.qty}</td>
        <td style={{textAlign:"center"}}>{item.lead_time}</td>
      </tr>
  
        )
      })
    } */}
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-6" role="tabpanel" aria-labelledby="ex-with-icons-tab-6">
      <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
      <th scope="col">Month</th>
        <th scope="col">Quantity</th>
        <th scope="col">Scrap Cost</th>
        <th scope="col">Scrap Reason</th>
      </tr>
    </thead>
    <tbody>
    {data?.scrap_item?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
       
       
        <td>{item?.created_at?.split("T")[0]}</td>
        <td style={{textAlign:"center"}}>{item?.scrapquantity}</td>
        <td style={{textAlign:"center"}}>{item?.scraptotalcost}</td>
        <td style={{textAlign:"center"}}>{item?.scrap_reason}</td>
      </tr>
  
        )
      })
    }
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-7" role="tabpanel" aria-labelledby="ex-with-icons-tab-7">
      <div className='table-responsive'>
        <table class="table table-sm">
    <thead>
      <tr>
      <th scope="col"></th>
        <th scope="col">Source</th>
        <th scope="col">Quantity</th>
        <th scope="col">Total</th>
        <th scope="col">Cost</th>
        <th scope="col">Profit margin</th>
      </tr>
    </thead>
    <tbody>
    {/* {data?.purchase_orders?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2===0?"#ffff":"#EAEAEA"}`}}>
       
        <td></td>
        <td>{item.supplier}</td>
        <td style={{textAlign:"center"}}>{item.location}</td>
        <td style={{textAlign:"center"}}>{item.date}</td>
        <td style={{textAlign:"center"}}>{item.expected}</td>
        <td style={{textAlign:"center"}}>{item?.pivot?.qty}</td>
        <td style={{textAlign:"center"}}>{item.lead_time}</td>
      </tr>
  
        )
      }) */}
    {/* } */}
    </tbody>
  </table>
        </div>
      </div>
    </div>
    {/* Tabs content */}
          </div>
  )
}

export default ItemStats